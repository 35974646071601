import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';
import { Form, Button, InputGroup } from 'react-bootstrap';

const RawDataViewer = ({ data }) => {
    const [searchString, setSearchString] = useState("");
    const [dataLines, setDataLines] = useState([]);
    const [displayData, setDisplayData] = useState("");

    // Convert JSON to an array of lines when data changes
    useEffect(() => {
        
        if (data) {
            setDataLines(JSON.stringify(data, null, 2).split('\n'))
        }

    }, [data])

    useEffect(() => {
        
        let newDisplayData = []

        if (searchString == ""){
            newDisplayData = dataLines
        } else {
            dataLines.forEach(line => {
                if (line.toLowerCase().includes(searchString)) { 
                    newDisplayData.push(line)
                }
            })
            }

            setDisplayData(newDisplayData.join("\n"));
    }, [dataLines, searchString]);

    function handleSearchChange(event) {
        setSearchString(event.target.value.toLowerCase());
    }

    const searchBar = () => (

        <form>
            <InputGroup >
            <Form.Control
               type="text"
               placeholder="Search..."
               value={searchString}
               onChange={handleSearchChange}
            />
            <Button variant="outline-secondary" onClick={() => setSearchString('')}>
                Clear
            </Button>
        </InputGroup>
        </form>

    );


    return (
        <div style={{marginTop: "1rem"}}>
            {searchBar()}
            <div className="json-viewer" style={{width: "80vw"}}>
                {displayData}
            </div>
        </div>
    );
};

export default RawDataViewer;
