import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const PreviewGraph = ({ data, lineConfigs, graphType }) => {

    const d3Container = useRef(null);

    function processTestLogsData(logs, config, yDomain = false) {
        const fieldName = config.key;
        let returnSeries = [];

        logs.forEach((logEntry, index) => {
            const timestamp = new Date(logEntry.event_timestamp).getTime();
            let value = logEntry.event_data[fieldName];

            if (config.dataType === "number") {
                value = parseFloat(value);

            }
            if (!isNaN(value) || config.dataType != "number") {
                returnSeries.push({ timestamp, value });
            }
        });

        return returnSeries;
    }


    useEffect(() => {
        if (data && d3Container.current) {

            const targetVolume = parseFloat(data.delivery_vtbi)
            const targetRate = parseFloat(data.delivery_rate)
            const testStart = new Date(data.test_start_time)

            const targetRuntimeMs = (((targetVolume / targetRate) * 3600) * 1000)
            const targetEndTime = new Date(testStart.getTime() + (targetRuntimeMs));

            // Set up SVG and dimensions
            const svgWidth = 375;
            const svgHeight = 225;
            const margin = { top: 5, right: 15, bottom: 5, left: 50 };
            const width = svgWidth - margin.left - margin.right;
            const height = svgHeight - margin.top - margin.bottom;

            // Clear previous SVG elements
            d3.select(d3Container.current).selectAll("*").remove();

            // Initialize SVG
            const svg = d3.select(d3Container.current).append('svg')
                .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Define the clipping path to stop lines printing outside the plot area
            svg.append("defs")
                .append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", width)
                .attr("height", height);


            let allTimestamps = [];
            let allValues = [];


            // extract numerical datasets to calculate domain ranges
            lineConfigs.forEach(config => {
                if (config.dataType == "number" && !config.normalizeData) {
                    const processedData = processTestLogsData(data.testLogs, config);
                    allTimestamps.push(...processedData.map(d => d.timestamp));
                    allValues.push(...processedData.map(d => d.value));
                }
            });


            // Calculate domain ranges dynamically based on processed data
            const xDomain = [Math.min(...allTimestamps), (Math.max(targetEndTime, ...allTimestamps))];

            const yDomain = (graphType == "deliveryGraph" ? [Math.min(...allValues), (Math.max(...allValues) * 1.1)] : [-30, 30]);

            const xScale = d3.scaleLinear()
                .domain(xDomain)
                .range([0, width]);

            const yScale = d3.scaleLinear()
                .domain(yDomain)
                .range([height, 0]);

            // Y-axis with a limited number of ticks
            const yAxis = d3.axisLeft(yScale)
                .tickValues([yDomain[0], yDomain[1]]) // Set explicit tick values to min and max
                .ticks(2)
                .tickFormat(d3.format('.2f')); // Optional: Format tick values (e.g., to 2 decimal places)


            const xAxis = d3.axisBottom(xScale)
                .ticks(0);

            // Draw the Y Axis
            const yAxisG = svg.append('g').call(yAxis);

            // Draw the data lines

            lineConfigs.forEach(config => {
                const line = d3.line()
                    .x(d => xScale(d.timestamp))
                    .y(d => yScale(d.value)) // Use 'value' here since that's the structure of the processed data
                    .curve(d3.curveMonotoneX);

                const processedData = processTestLogsData(data.testLogs, config, yDomain); // Process data for each line
                svg.append('path')
                    .datum(processedData)
                    .attr('fill', 'none')
                    .attr('stroke', config.color)
                    .attr('stroke-width', 1.5)
                    .attr('opacity', 1) // Set line opacity
                    .attr('d', line)
                    .attr("clip-path", "url(#clip)");

                // Class name for the dots of this line
                const dotClass = `dot-line-${config.key}`;

                // Draw dots at each data point for this line
                svg.selectAll(`.${dotClass}`) // Unique class for each line's dots
                    .data(processedData)
                    .enter().append('circle')
                    .attr('class', dotClass)
                    .attr('cx', d => xScale(d.timestamp))
                    .attr('cy', d => yScale(d.value))
                    .attr('r', .75) // Radius of the dots
                    .attr('fill', config.color); // Fill color of the dots
            });


            // Add the X Axis
            svg.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(xAxis);

            // Add the Y Axis
            svg.append('g')
                .call(yAxis);

            /*/ Add title
            svg.append('text')
                .attr('x', (width / 2))
                .attr('y', 0 - (margin.top / 2))
                .attr('text-anchor', 'middle')
                .style('font-size', '20px')
                .style('text-decoration', 'underline')
                .text(`${}`);*/

            // Draw horizontal dotted line at targetVolume on Y-axis
            if (graphType == "deliveryGraph") {
                svg.append('line')
                    .attr('x1', xScale(xDomain[0]))
                    .attr('y1', yScale(targetVolume))
                    .attr('x2', xScale(xDomain[1]))
                    .attr('y2', yScale(targetVolume))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 1)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');

                //draw diagonal line for ideal delivery
                svg.append('line')
                    .attr('x1', xScale(xDomain[0]))
                    .attr('y1', yScale(0))
                    .attr('x2', xScale(targetEndTime))
                    .attr('y2', yScale(targetVolume))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 1)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');

            } else {
                svg.append('line')
                    .attr('x1', xScale(xDomain[0]))
                    .attr('y1', yScale(0))
                    .attr('x2', xScale(xDomain[1]))
                    .attr('y2', yScale(0))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 1)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');

                svg.append('line')
                    .attr('x1', xScale(xDomain[0]))
                    .attr('y1', yScale(10))
                    .attr('x2', xScale(xDomain[1]))
                    .attr('y2', yScale(10))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 1)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');

                svg.append('line')
                    .attr('x1', xScale(xDomain[0]))
                    .attr('y1', yScale(-10))
                    .attr('x2', xScale(xDomain[1]))
                    .attr('y2', yScale(-10))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 1)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '2,2');

            }

            // Draw vertical dotted line at targetRuntimeMin on X-axis
            svg.append('line')
                .attr('x1', xScale(targetEndTime))
                .attr('y1', yScale(yDomain[0]))
                .attr('x2', xScale(targetEndTime))
                .attr('y2', yScale(yDomain[1]))
                .attr('stroke', 'green')
                .attr('stroke-width', 1)
                .attr('opacity', 0.5)
                .attr('stroke-dasharray', '2,2');

        }



    }, [data, lineConfigs]); // Redraw graph when data changes

    return (
        <div ref={d3Container} style={{ height: '100%', padding: "1rem", margin: "auto" }} />
    );
};

export default PreviewGraph;
