import React, { useState, useEffect } from 'react';
import TimeSeriesGraph from './TimeSeriesGraph.jsx';
import Button from 'react-bootstrap/Button';

import '../../styles/Graphing.css';

const GraphContainer = ({ tr_id, graphSettings, setGraphSettings, modalTab, setModalTab, activeImageIndex, setActiveImageIndex, data }) => {

    const [currentTrId, setCurrentTrId] = useState(tr_id);
    const [inputTrId, setInputTrId] = useState('');


    /* Debug / U used Graph Fields Reminder
        mainGraphSettings.mainGraphActiveFields: ["balance_ml", "delivery_vol_delivered"],
        mainGraphSettings.altGraphActiveFields: ["dc_fluid_starting_ml", "dc_fluid_volume_ml"]

        battery_charge_percent: { label: 'Battery Charge', units: "%", color: 'lawngreen', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 },
        dc_fluid_delta_ml: { label: 'DC Fluid Delta', units: "ml", color: 'cadetblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
        dc_fluid_volume_ml: { label: 'DC Fluid Volume', units: "ml", color: 'cornflowerblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
        dc_fluid_starting_ml: { label: 'DC Fluid Starting Level', units: "ml", color: 'lightseagreen', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
        dc_fluid_height_mm: { label: 'DC Fluid Height', units: "mm", color: 'deepskyblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 },
        delivery_vol_remaining: { label: 'Volume Remaining', units: "ml", color: 'cornflowerblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
        drops_detected_lower: { label: 'Drops Detected (lower)', units: "#", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 },
        drops_detected_upper: { label: 'Drops Detected (upper)', color: 'cornflowerblue', units: "#", dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 },
         encoder_position_actual: { label: 'Encoder Position', units: "normalized", color: 'lightblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 },
        delivery_time_remaining: { label: 'Delivery Time Remaining', units: "sec", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
         */

    const mainGraphFields = {
        balance_ml: { label: 'Balance Vol', units: "mL", color: 'red', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 3, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        delivery_vol_delivered_raw: { label: 'Measured Vol', units: "mL", color: 'royalblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        controller_status: { label: 'Controller Status', units: "", color: 'lightskyblue', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [], eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        delivery_status: { label: 'Delivery Status', units: "", color: 'yellow', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [], eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        
        dripchamber_status: { label: 'Dripchamber Status', units: "", color: 'lightgreen', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        alarm_status: { label: 'Alarm', units: "", color: 'orangered', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["None"] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        errorcode: { label: 'Error Code', units: "", color: 'red', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["0x0"] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        dc_drop_progress_percent: { label: 'Drop Tip Progress', units: "%", color: 'yellow', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 100], decimalPlaces: 0, opacity: .25 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_delivered: { label: 'Drops Delivered', units: "#", color: 'mediumblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_measured: { label: 'Drops Measured', units: "#", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_vol_last: { label: 'Drop Volume', units: "μL", color: 'darkcyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 250], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        volume: { label: 'Drop Log Volume', units: "μL", color: 'cyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 250], decimalPlaces: 2, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},

        dc_fluid_level_px: { label: 'Fluid Level', units: "px", color: 'darkcyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        dc_fluid_volume_ml: { label: 'Fluid Level', units: "mL", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 8], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        motor_position_actual: { label: 'Motor Position (actual)', units: "", color: 'magenta', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        motor_position_command: { label: 'Motor Position (cmd)', units: "", color: 'maroon', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        pump_status: { label: 'Pump Activity', units: "", color: 'pink', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [], eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        battery_charge_percent: { label: 'Battery Charge', units: "%", color: 'lawngreen', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 100], decimalPlaces: 0, opacity: 1 },
        
        delivery_time_remaining: { label: 'FCL Delivery Timer', units: "sec", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 },
        calculated_t0: { label: 'Calculated T0', units: "", color: 'lightskyblue', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [undefined, NaN], eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        untracked_test_periods: { label: 'Untracked Test Periods', units: "", color: 'grey', graphType: 'greyout', opacity: .8},
    }

    const accuracyGraphFields = {
        measurement_error_percent: { label: 'Measurement Error', units: "%", color: 'dodgerblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [-100, 100], decimalPlaces: 3, opacity: 1, display: true , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        total_error_percent: { label: 'Total Error', color: 'firebrick', units: "%", dataType: 'number', normalizeData: false, graphType: 'line', range: [-100, 100], decimalPlaces: 2, opacity: 1, display: true , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        controller_status: { label: 'Controller Status', units: "", color: 'lightskyblue', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        dripchamber_status: { label: 'Dripchamber Status', units: "", color: 'lightgreen', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        alarm_status: { label: 'Alarm', units: "", color: 'orangered', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["None"] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        errorcode: { label: 'Error Code', units: "", color: 'red', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["0x0"] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        dc_drop_progress_percent: { label: 'Drop Tip Progress', units: "%", color: 'aqua', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 100], decimalPlaces: 0, opacity: .25 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_delivered: { label: 'Drops Delivered', units: "#", color: 'mediumblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_measured: { label: 'Drops Measured', units: "#", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        volume: { label: 'Drop Log Volume', units: "μL", color: 'cyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 250], decimalPlaces: 2, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},

        dc_fluid_level_px: { label: 'DC Fluid Level', units: "px", color: 'darkcyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA" },

        motor_position_actual: { label: 'Motor Position (actual)', units: "", color: 'magenta', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        motor_position_command: { label: 'Motor Position (cmd)', units: "", color: 'maroon', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        untracked_test_periods: { label: 'Untracked Test Periods', units: "", color: 'grey', graphType: 'greyout', opacity: .8},
    }


    const dropGraphFields = {
        
        avg: { label: 'Drop Log Avg', units: "μL", color: 'brown', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 250], decimalPlaces: 2, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},
        boundary_ratio: { label: 'Boundary Ratio', units: "", color: 'orangered', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 1], decimalPlaces: 2, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},
        
        sats: { label: 'Satellites', units: "#", color: 'orange', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 10], decimalPlaces: 0, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},

        drops_detected_lower: { label: 'Drops Detected (lower)', units: "#", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_detected_upper: { label: 'Drops Detected (upper)', units: "#", color: 'cornflowerblue', units: "#", dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
    
        drops_delivered: { label: 'FCL Drops Delivered', units: "#", color: 'mediumblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        drops_measured: { label: 'FCL Drops Measured', units: "#", color: 'cornflowerblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        volume: { label: 'Drop Log Volume', units: "μL", color: 'cyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 250], decimalPlaces: 2, opacity: 1 , eventName: "drop_log", eventType: "TEST_FLOWRATE_DATA"},


        dc_fluid_delta_ml: { label: 'DC Fluid Delta', units: "mL", color: 'cadetblue', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        dc_fluid_volume_ml: { label: 'DC Fluid Volume', units: "mL", color: 'cornflowerblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
       
        dc_fluid_level_px: { label: 'DC Fluid Level', units: "px", color: 'darkcyan', dataType: 'number', normalizeData: true, graphType: 'line', range: [], decimalPlaces: 0, opacity: 1 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        dc_drop_progress_percent: { label: 'Drop Tip Progress', units: "%", color: 'aqua', dataType: 'number', normalizeData: true, graphType: 'line', range: [0, 100], decimalPlaces: 0, opacity: .25 , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},

        controller_status: { label: 'Controller Status', color: 'lightskyblue', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        dripchamber_status: { label: 'Dripchamber Status', units: "", color: 'lightgreen', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: [] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        alarm_status: { label: 'Alarm', color: 'orangered', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["None"] , eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA"},
        errorcode: { label: 'Error Code', color: 'red', dataType: 'text', graphType: 'state', opacity: 1, ignoreValues: ["0x0"], eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA" },
        
        motor_position_actual: { label: 'Motor Position (actual)', color: 'magenta', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1, eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA" },
        motor_position_command: { label: 'Motor Position (cmd)', color: 'maroon', dataType: 'number', normalizeData: true, graphType: 'line', range: [1100, 1800], decimalPlaces: 2, opacity: 1, eventName: "infdev_timeseries", eventType: "TEST_FLOWRATE_DATA" },

        untracked_test_periods: { label: 'Untracked Test Periods', color: 'grey', graphType: 'greyout', opacity: .8},
    }

    const deliveryCheckboxGroups = [
        {
          label: "Total Volume",
          fields: ["balance_ml", "delivery_vol_delivered_raw"],
        },
        {
          label: "Fluid Drops",
          fields: ["dc_drop_progress_percent", "drops_delivered", "drops_measured", "volume"],
        },
        {
          label: "Level/Position",
          fields: ["dc_fluid_level_px", "dc_fluid_volume_ml", "motor_position_actual", "motor_position_command"],
        },
        {
          label: "Status",
          fields: ["dripchamber_status", "controller_status", "delivery_status", "pump_status"],
        },
        {
          label: "Debug",
          fields: ["alarm_status", "errorcode", "delivery_time_remaining", "battery_charge_percent"],
        },
    ];

    const accuracyCheckboxGroups = [
        {
          label: "Error",
          fields: ["measurement_error_percent", "total_error_percent"],
        },
        {
          label: "Fluid Drops",
          fields: ["dc_drop_progress_percent", "drops_delivered", "drops_measured", "volume"],
        },
        {
          label: "Level/Position",
          fields: ["dc_fluid_level_px", "motor_position_actual", "motor_position_command"],
        },
        {
          label: "Status",
          fields: ["dripchamber_status", "controller_status"],
        },
        {
          label: "Debug",
          fields: ["alarm_status", "errorcode", "untracked_test_periods"],
        },
    ];

    const dropCheckboxGroups = [
        {
          label: "Drop Log",
          fields: ["volume", "avg", "boundary_ratio", "sats"],
        },
        {
          label: "Fluid Drops",
          fields: ["drops_detected_lower", "drops_detected_upper", "drops_delivered", "drops_measured", "volume"],
        },
        {
          label: "Level/Position",
          fields: ["dc_fluid_level_px", "dc_fluid_volume_ml", "dc_fluid_delta_ml", "dc_drop_progress_percent", "motor_position_actual", "motor_position_command"],
        },
        {
          label: "Status",
          fields: ["dripchamber_status", "controller_status"],
        },
        {
          label: "Debug",
          fields: ["alarm_status", "errorcode", "untracked_test_periods"],
        },
    ];


    const handleInputChange = (e) => {
        setInputTrId(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newTrId = parseInt(inputTrId, 10);
        if (!isNaN(newTrId)) {
            setCurrentTrId(newTrId);
        } else {
            alert("Please enter a valid number for tr_id");
        }
    };

    const handleMainCheckboxChange = (key) => {
        setGraphSettings(prevSettings => ({
            ...prevSettings,
            mainGraphActiveFields: {
                ...prevSettings.mainGraphActiveFields,
                [key]: !prevSettings.mainGraphActiveFields[key],
            },
        })); 
    };


    const handleAccuracyCheckboxChange = (key) => {
        setGraphSettings(prevSettings => ({
            ...prevSettings,
            accuracyGraphActiveFields: {
                ...prevSettings.accuracyGraphActiveFields,
                [key]: !prevSettings.accuracyGraphActiveFields[key],
            },
        }));
    };

    const handleDropCheckboxChange = (key) => {
        setGraphSettings(prevSettings => ({
            ...prevSettings,
            dropGraphActiveFields: {
                ...prevSettings.dropGraphActiveFields,
                [key]: !prevSettings.dropGraphActiveFields[key],
            },
        }));
    };

    const isDataLoaded = data && data.balanceReadings && data.balanceReadings.length > 0;

    const mainGraphFieldsSorted = Object.entries(mainGraphFields)
    const accuracyGraphFieldsSorted = Object.entries(accuracyGraphFields)
    const dropGraphFieldsSorted = Object.entries(dropGraphFields)


    const mainGraphLines = Object.keys(graphSettings.mainGraphActiveFields)
        .filter(field => graphSettings.mainGraphActiveFields[field])
        .map(field => ({
            key: field,
            ...mainGraphFields[field]
        }));


    const accuracyGraphLines = Object.keys(graphSettings.accuracyGraphActiveFields)
        .filter(field => graphSettings.accuracyGraphActiveFields[field])
        .map(field => ({
            key: field,
            ...accuracyGraphFields[field]
        }));


    const dropGraphLines = Object.keys(graphSettings.dropGraphActiveFields)
    .filter(field => graphSettings.dropGraphActiveFields[field])
    .map(field => ({
        key: field,
        ...dropGraphFields[field]
    }));

    /*
        <form onSubmit={handleSubmit}>
         
        <input type="text" value={inputTrId} onChange={handleInputChange} placeholder={currentTrId} /><button type="submit">Load Data</button>
 
        </form>
    */
    return (
        <div  style={{width: "80vw"}}>

            {isDataLoaded && modalTab == "mainGraph" ? (

                <div style={{ width: '100%', align: "center", margin: "15px", padding: "10px" }}>
                    <TimeSeriesGraph key={`${tr_id}-delivery-graph`} graphFormat="delivery" data={data} lineConfigs={mainGraphLines} modalTab={modalTab} setModalTab={setModalTab} activeImageIndex={activeImageIndex} setActiveImageIndex={setActiveImageIndex}/>
                    <div className="checkbox-container" >
                        {deliveryCheckboxGroups.map((group) => (
                        <div key={group.label} className="checkbox-group" >
                            <h4 style={{ textAlign: 'left', marginLeft: '5px' }}>{group.label}</h4>
                            {group.fields.map((key) => (
                            <label
                                key={key}
                                className="checkbox-label"
                                style={{ color: graphSettings.mainGraphActiveFields[key] ? mainGraphFields[key].color : 'ivory' }}
                            >
                                <input
                                type="checkbox"
                                className="checkbox-custom"
                                checked={graphSettings.mainGraphActiveFields[key]}
                                onChange={() => handleMainCheckboxChange(key)}
                                />
                                <span className="checkbox-label-text">
                                    {mainGraphFields[key].label}
                                    {mainGraphFields[key].units && (
                                        <small> ({mainGraphFields[key].units})</small>
                                    )}
                                </span>
                            </label>
                            ))}
                        </div>
                        ))}
                    </div>

                </div>
            ) : isDataLoaded && modalTab == "accuracyGraph" ? (

                <div style={{ width: '100%', align: "center", margin: "15px", padding: "10px" }}>
                    <TimeSeriesGraph key={`${tr_id}-accuracy-graph`} graphFormat="accuracy" data={data} lineConfigs={accuracyGraphLines} modalTab={modalTab} setModalTab={setModalTab} activeImageIndex={activeImageIndex} setActiveImageIndex={setActiveImageIndex}/>

                    <div className="checkbox-container">
                        {accuracyCheckboxGroups.map((group) => (
                        <div key={group.label} className="checkbox-group">
                            <h4 style={{ textAlign: 'left', marginLeft: '5px' }}>{group.label}</h4>
                            {group.fields.map((key) => (
                            <label
                                key={key}
                                className="checkbox-label"
                                style={{ color: graphSettings.accuracyGraphActiveFields[key] ? accuracyGraphFields[key].color : 'ivory' }}
                            >
                                <input
                                type="checkbox"
                                className="checkbox-custom"
                                checked={graphSettings.accuracyGraphActiveFields[key]}
                                onChange={() => handleAccuracyCheckboxChange(key)}
                                />
                                <span className="checkbox-label-text">
                                    {accuracyGraphFields[key].label}
                                    {accuracyGraphFields[key].units && (
                                        <small> ({accuracyGraphFields[key].units})</small>
                                    )}
                                </span>
                            </label>
                            ))}
                        </div>
                        ))}
                    </div>

                </div>
            )  : isDataLoaded && modalTab == "dropGraph" ? (

                <div style={{ width: '100%', align: "center", margin: "15px", padding: "10px"}}>
                    <TimeSeriesGraph key={`${tr_id}-drop-graph`} graphFormat="drop" data={data} lineConfigs={dropGraphLines} modalTab={modalTab} setModalTab={setModalTab} activeImageIndex={activeImageIndex} setActiveImageIndex={setActiveImageIndex}/>
                    <div className="checkbox-container">
                        {dropCheckboxGroups.map((group) => (
                            <div key={group.label} className="checkbox-group">
                                <h4 style={{ textAlign: 'left', marginLeft: '5px' }}>{group.label}</h4>
                                {group.fields.map((key) => {
                                const field = dropGraphFields[key];
                                if (!field) {
                                    console.error(`Field "${key}" is not defined in dropGraphFields.`);
                                    return null; // Skip rendering if the field is missing
                                }
                                return (
                                    <label
                                    key={key}
                                    className="checkbox-label"
                                    style={{ color: graphSettings.dropGraphActiveFields[key] ? field.color : 'ivory' }}
                                    >
                                    <input
                                        type="checkbox"
                                        className="checkbox-custom"
                                        checked={graphSettings.dropGraphActiveFields[key]}
                                        onChange={() => handleDropCheckboxChange(key)}
                                    />
                                    <span className="checkbox-label-text">
                                        {field.label}
                                        {field.units && (
                                            <small> ({field.units})</small>
                                        )}
                                    </span>
                                    </label>
                                );
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            ) :

                <div>Loading graph...</div>
            }
        </div>
    );
}

export default GraphContainer;