import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { downloadExcelFile } from "./TestRunExcelDownloader";
import CreateSoftwareConfigPopup from "./CreateSoftwareConfigPopup";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/SoftwareStats.css";
import { getErrorStats, getSoftwareConfigs, updateSoftwareConfig, createSoftwareConfig } from "../../services/apiService.js";
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const SoftwareVersionTable = () => {
  const [softwareConfigs, setSoftwareConfigs] = useState([]);
  const [editingConfig, setEditingConfig] = useState(null);
  const [editValues, setEditValues] = useState({ name: "", universion: "" });
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [newConfig, setNewConfig] = useState({ name: "", universion: "" });
  const [errorStats, setErrorStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDownloadConfig, setSelectedDownloadConfig] = useState(null);
  const [downloadedData, setDownloadedData] = useState(null); 
  const [sortConfig, setSortConfig] = useState({ key: "createdAt", direction: "desc" });
  const [showPopup, setShowPopup] = useState(false);

  const fetchSoftwareConfigs = async () => {
    try {
      const apiData = await getSoftwareConfigs();

      // Clean up null and empty values
      const cleanedData = apiData.map(row =>
        Object.fromEntries(
          Object.entries(row).filter(([_, value]) =>
            value !== null &&
            value !== "" &&
            (typeof value !== "string" || value.trim() !== "" && !/^[\u0000-\u001F\u007F]+$/.test(value))
          )
        )
      );

      setSoftwareConfigs(cleanedData);
      console.log("Fetched software configs (cleaned)", cleanedData);
    } catch (error) {
      console.error("Error fetching software configs:", error);
    }
  };

  useEffect(() => {
    fetchSoftwareConfigs();
  }, []);

  useEffect(() => {
    if (softwareConfigs.length === 0) return; // Prevent unnecessary fetches

    async function fetchAllData() {
      const results = {};
      setLoading(true);

      for (const config of softwareConfigs) {
        try {
          const apiData = await getErrorStats(config);
          results[config.id] = {
            totalRuns: apiData.totalCount || 0,
            runsWithErrors: apiData.errorFrequencies
              ? apiData.errorFrequencies.reduce(
                  (sum, entry) => (entry.errorcode !== null ? sum + entry.count : sum),
                  0
                )
              : 0,
            errorFrequencies: apiData.errorFrequencies || []
          };
        } catch (error) {
          console.error(`Error fetching data for ${config.id}:`, error);
          results[config.id] = { totalRuns: 0, runsWithErrors: 0, errorFrequencies: [] };
        }
      }

      setErrorStats(results);
      setLoading(false);
    }

    fetchAllData();
  }, [softwareConfigs]);

  // useEffect for handling download data fetching
  useEffect(() => {
    if (!selectedDownloadConfig) return; // Prevent running if no download request

    const fetchDownloadData = async () => {
      try {
        console.log(`Fetching individual runs for: ${selectedDownloadConfig.id}`);
        const apiData = await getErrorStats({ ...selectedDownloadConfig, individualRuns: true }); 
        setDownloadedData(apiData); // Store fetched data
        console.log("Fetched run data:", apiData); // Simulate handling the data
      } catch (error) {
        console.error("Error fetching individual runs:", error);
      }
    };

    fetchDownloadData();
  }, [selectedDownloadConfig]);

  // Generate and Download Excel once data is available
  useEffect(() => {
    if (downloadedData && selectedDownloadConfig) {
      downloadExcelFile(selectedDownloadConfig, downloadedData, errorStats);
      setSelectedDownloadConfig(null);
      setDownloadedData(null);
    }
  }, [downloadedData]); // Runs when run data is available

  // Handle Download Click
  const handleDownload = (config) => {
    setSelectedDownloadConfig(config); // Trigger useEffect by setting state
  };

   // Function to handle sorting
   const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleEditClick = (config) => {
    setEditingConfig(config);
    setEditValues({ name: config.name, universion: config.universion });
  };

  const handleChange = (e) => {
    setEditValues({ ...editValues, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (!editingConfig) return;

    // Ensure name and universion are not already used
    if (editingConfig.name !== editValues.name) {
      if (softwareConfigs.some(config => config.name === editValues.name)) {
        alert("The provided name is already in use. Please choose a different name.");
        return;
      }
    }
    if (editingConfig.universion !== editValues.universion) {
      if (softwareConfigs.some(config => config.universion === editValues.universion)) {
        alert("The provided universion is already in use. Please choose a different universion.");
        return;
      }
    }

    try {
      await updateSoftwareConfig({
        id: editingConfig.id,
        currentName: editingConfig.name,
        newName: editValues.name,
        currentUniversion: editingConfig.universion,
        newUniversion: editValues.universion,
      });

      setSoftwareConfigs((prevConfigs) =>
        prevConfigs.map((config) =>
          config.id === editingConfig.id 
            ? { ...config, name: editValues.name, universion: editValues.universion }
            : config
        )
      );

      setEditingConfig(null);
    } catch (error) {
      alert("Failed to edit software configuration.");
      console.error("Error updating software config:", error);
    }
  };

  const handleCancel = () => {
    setEditingConfig(null);
  };


  // Sort the software configurations based on selected column and direction
  const sortedConfigs = [...softwareConfigs].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const valA = a[sortConfig.key] || "";
    const valB = b[sortConfig.key] || "";

    // Convert date strings to actual Date objects for proper sorting
    if (sortConfig.key === "createdAt" || sortConfig.key === "usedLast") {
      return sortConfig.direction === "asc"
        ? new Date(valA) - new Date(valB)
        : new Date(valB) - new Date(valA);
    }

    // Default string comparison
    return sortConfig.direction === "asc"
      ? valA.toString().localeCompare(valB.toString())
      : valB.toString().localeCompare(valA.toString());
  });

  // Function to get sorting arrow icon with red highlight for active column
  const getSortArrow = (column) => {
    const isActive = sortConfig.key === column;
    const arrow = sortConfig.direction === "asc" ? "▲" : "▼";

    return (
      <span style={{ color: isActive ? "red" : "#aaa", fontWeight: isActive ? "bold" : "normal" }}>
        {isActive ? arrow : "⇅"} {/* Default arrow when not active */}
      </span>
    );
  };

  // Function to convert UTC to EST
  const convertToEST = (utcDate) => {
    if (!utcDate) return "N/A"; // Handle empty/null values

    const date = new Date(utcDate); // Convert to Date object
    return date.toLocaleString("en-US", { timeZone: "America/New_York" }); // Convert to EST
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle null or undefined values
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extracts YYYY-MM-DD
  };

  const versionMapping = softwareConfigs.reduce((acc, config) => {
    Object.keys(config).forEach((key) => {
      if (
        key !== "id" &&
        key !== "name" &&
        key !== "createdAt" &&
        key !== "updatedAt" &&
        key !== "usedFirst" &&
        key !== "usedLast"
      ) {
        if (!acc[key]) {
          acc[key] = new Set(); // Use a Set to store unique values
        }
        acc[key].add(config[key]); // Add unique universion values
      }
    });
    return acc;
  }, {});
  
  // Convert Set values to arrays for easier use in JSX
  const formattedVersionMapping = Object.fromEntries(
    Object.entries(versionMapping).map(([key, value]) => [key, Array.from(value)])
  );
  
  return (
    <Container className="mt-5">
      <div className="p-4 bg-light rounded shadow-sm">
        <div className="d-flex justify-content-end mb-3">
          <button className="btn btn-primary" onClick={() => setShowPopup(true)}>
            New Configuration
          </button>
        </div>
        <h3 className="text-center mb-4">Software Version Stats</h3>
        <div className="table-responsive">
          <Table striped bordered hover className="shadow-sm">
            <thead className="table-light">
              <tr>
                <th></th>
                <th onClick={() => handleSort("createdAt")} style={{ cursor: "pointer" }}>
                  Created Date {getSortArrow("createdAt")}
                </th>
                <th onClick={() => handleSort("usedLast")} style={{ cursor: "pointer" }}>
                  Last Used {getSortArrow("usedLast")}
                </th>
                <th >Software Configuration</th>
                <th>Universion</th>
                <th>Total Runs</th>
                <th style={{maxWidth: "100px"}}>Runs with Errors</th>
                <th style={{maxWidth: "150px"}}>Percent of Runs with Errors</th>
                <th style={{maxWidth: "125px"}} className="text-center">Download Run Data</th>
              </tr>
            </thead>
            <tbody>
              {sortedConfigs.map((config, index) => {
                const isEditing = editingConfig?.id === config.id;
                const stats = errorStats[config.id] || { totalRuns: 0, runsWithErrors: 0 };
                const percentWithErrors = stats.totalRuns
                  ? ((stats.runsWithErrors / stats.totalRuns) * 100).toFixed(2)
                  : "0.00";
                const isSelected = selectedConfig?.id === config.id;

                return (
                  <React.Fragment key={index}>
                    {/* Clickable Row */}
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => setSelectedConfig(isSelected ? null : config)}
                    >
                      <td>                        
                        {isEditing ? (
                          <>
                            <IconButton size="small" color="success" onClick={(e) => {
                                e.stopPropagation(); 
                                handleSave();
                              }}>
                              <CheckIcon fontSize="small" />
                            </IconButton>
                            <IconButton size="small" color="error" onClick={(e) => {
                                e.stopPropagation(); 
                                handleCancel();
                              }}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </>
                        ) : (
                        !editingConfig && (
                          <IconButton size="small" onClick={(e) => {
                            e.stopPropagation(); 
                            handleEditClick(config);
                          }}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        ))}
                      </td>
                      <td className="text-center">{formatDate(config.createdAt)}</td>
                      <td className="text-center">{formatDate(config.usedLast)}</td>
                      <td className="fw-bold">
                        {isEditing ? (
                          <input
                            type="text"
                            name="name"
                            value={editValues.name}
                            onChange={handleChange}
                            className="form-control form-control-sm"
                            onClick={(e) => {e.stopPropagation()}}
                          />
                        ) : (
                          config.name
                        )}
                      </td>
                      <td className="text-center">
                        {isEditing ? (
                          <input
                            type="text"
                            name="universion"
                            value={editValues.universion}
                            onChange={handleChange}
                            className="form-control form-control-sm"
                            onClick={(e) => {e.stopPropagation()}}
                          />
                        ) : (
                          config.universion
                        )}
                      </td>
                      <td className="text-center">{loading ? "Loading..." : stats.totalRuns}</td>
                      <td className="text-center">{loading ? "Loading..." : stats.runsWithErrors}</td>
                      <td className="text-center">{loading ? "Loading..." : `${percentWithErrors}%`}</td>
                      <td className="text-center">
                        <IconButton size="small" onClick={(e) => {
                          e.stopPropagation(); 
                          handleDownload(config);
                        }}>
                          <DownloadIcon fontSize="small" /> 
                        </IconButton>
                        
                      </td>
                    </tr>

                    {/* Expanded Details Row */}
                    {isSelected && (
                      <tr>
                        <td colSpan="9">
                          <div className="p-3 bg-light rounded shadow-sm">
                            <h5 className="text-center mb-3">Software Version Details</h5>
                              <div className="table-responsive">
                                <Table striped bordered className="shadow-sm" size="sm">
                                  <thead className="table-light">
                                    <tr>
                                      {Object.keys(config)
                                        .filter(key => !["id", "name", "universion", "usedFirst", "usedLast", "createdAt", "updatedAt"].includes(key)) // Exclude keys from the table
                                        .map((key, idx) => {
                                          // Define a mapping of keys to desired column names
                                          const columnNameMap = {
                                            linux_sw_version: "Linux",
                                            gui_sw_version: "GUI",
                                            imx7_m4_version: "M4",
                                            fcl_version: "FCL",
                                            a7_sw_version: "A7",
                                            a7_control_version: "A7Ctrl",
                                            stm32_m4_version: "STM32",
                                            stm8_pri_fw_build: "AVR",
                                          };
                                          
                                          return (
                                            <th key={idx} className="text-center" style={{ fontSize: "12px", padding: "4px" }}>
                                              {columnNameMap[key] || key} {/* Fallback to key if not mapped */}
                                            </th>
                                          );
                                        })}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {Object.entries(config)
                                        .filter(([key]) => !["id", "name", "universion", "usedFirst", "usedLast", "createdAt", "updatedAt"].includes(key)) // Exclude keys from the row values
                                        .map(([_, value], idx) => (
                                          <td key={idx} className="text-center" style={{ fontSize: "12px", padding: "4px" }}>
                                            {value}
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            {/* Error Statistics Section */}
                            {stats.totalRuns > 0 ? (
                              stats.runsWithErrors > 0 ? (
                                <div className="mt-4">
                                  <h5 className="text-center mb-3">Error Statistics</h5>
                                  <Table striped bordered className="shadow-sm" size="sm">
                                    <thead className="table-light">
                                      <tr>
                                        <th>Error Code</th>
                                        <th>Number of Errors</th>
                                        <th>Percent of Errors</th>
                                        <th>Percent of Total Runs</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {errorStats[config.id].errorFrequencies
                                        .filter(error => error.count > 0) // Hide rows where number of errors is 0
                                        .map((error, idx) => {
                                          const totalErrors = errorStats[config.id].runsWithErrors || 1;
                                          const totalRuns = errorStats[config.id].totalRuns || 1;

                                          const percentOfErrors = ((error.count / totalErrors) * 100).toFixed(2);
                                          const percentOfTotalRuns = ((error.count / totalRuns) * 100).toFixed(2);

                                          return (
                                            <tr key={idx}>
                                              <td className="text-center">{error.errorcode || "N/A"}</td>
                                              <td className="text-center">{error.count}</td>
                                              <td className="text-center">{`${percentOfErrors}%`}</td>
                                              <td className="text-center">{`${percentOfTotalRuns}%`}</td>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <p className="text-center text-muted mt-3">No errors</p>
                              )
                            ) : <p className="text-center text-muted mt-3">No runs found</p>} 
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          {showPopup && (
            <CreateSoftwareConfigPopup
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              newConfig={newConfig}
              setNewConfig={setNewConfig}
              existingConfigs={softwareConfigs}
              versionMapping={formattedVersionMapping} 
              refreshConfigs={fetchSoftwareConfigs}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default SoftwareVersionTable;