import * as XLSX from "xlsx";

export const downloadExcelFile = (selectedConfig, downloadedData, errorStats) => {
    if (!downloadedData || !selectedConfig) return;

    // Function to convert UTC to EST
    const convertToEST = (utcDate) => {
        if (!utcDate) return "N/A"; // Handle empty/null values

        const date = new Date(utcDate); // Convert to Date object
        return date.toLocaleString("en-US", { timeZone: "America/New_York" }); // Convert to EST
    };

    // Function to Add AutoFilter for Run Data Table
    const addAutoFilter = (ws, startRow, endRow, endCol) => {
        ws["!autofilter"] = {
        ref: XLSX.utils.encode_range({
            s: { r: startRow, c: 0 }, 
            e: { r: endRow, c: endCol }
        }) // AutoFilter from headers to last row
        };
    };

    const currDate = new Date();
    const wb = XLSX.utils.book_new();

    const columnNameMap = {
        linux_sw_version: "Linux",
        gui_sw_version: "GUI",
        imx7_m4_version: "M4",
        fcl_version: "FCL",
        a7_sw_version: "A7",
        a7_control_version: "A7Ctrl",
        stm32_m4_version: "STM32",
        stm8_pri_fw_build: "AVR",
    };

    // Convert Software Version Details into key-value pairs with proper date conversion
    // Apply mapping to softwareDetailsKeys
    const softwareDetailsKeys = [Object.keys(selectedConfig)     // First row (Keys)
        .map(key => columnNameMap[key] || key)]; // Replace keys with mapped names, fallback to key if not mapped
    // Convert time values to EST
    const softwareDetailsValues = [
        Object.keys(selectedConfig).map(key => 
            ["usedFirst", "usedLast", "createdAt", "updatedAt"].includes(key) 
                ? convertToEST(selectedConfig[key])  // Convert these specific fields
                : selectedConfig[key]  // Keep other values unchanged
        )
    ];

    // Add Two Blank Rows
    const blankRows = [[""], [""]];

    // Convert Runs Data into an array with headers
    const runsData = downloadedData?.data || []; // Extract `data` from API response
    const runHeaders = ["Test #", "Test ID", "Serial #", "Delivery Rate (mL/hr)", "Alarm Status", "Error Code", "Time Remaining (seconds)", "Exclude Flag", "Start Time (EST)", "End Time (EST)"];
    const runValues = runsData.map(run => [
        run.test_number,
        run.id,
        run.infdev_sn,
        run.delivery_rate,
        run.alarm_status,
        run.errorcode,
        run.delivery_time_remaining,
        run.exclude_flag,
        convertToEST(run.test_start_time),
        convertToEST(run.test_end_time)
    ]);

    // First Sheet: "Error Stats"
    // Software Version Stats Row (From the main table)
    const stats = errorStats[selectedConfig.id] || { totalRuns: 0, runsWithErrors: 0 };
    const percentWithErrors = stats.totalRuns 
        ? ((stats.runsWithErrors / stats.totalRuns) * 100).toFixed(2) 
        : "0.00";

    const softwareStatsHeaders = [["id", "Configuration Name", "Universion", "Total Runs", "Runs with Errors", "Percent of Runs with Errors"]];
    const softwareStatsValues = [[
        selectedConfig.id,
        selectedConfig.name,
        selectedConfig.universion,
        stats.totalRuns,
        stats.runsWithErrors,
        `${percentWithErrors}%`
    ]];

    // Error Statistics Table (Only if runsWithErrors > 0)
    const errorHeaders = [["Error Code", "Number of Errors", "Percent of Errors", "Percent of Total Runs"]];
    const errorValues = stats.runsWithErrors > 0 
        ? stats.errorFrequencies
            .filter(error => error.count > 0) // Hide errors with count = 0
            .map(error => [
            error.errorcode || "N/A",
            error.count,
            ((error.count / stats.runsWithErrors) * 100).toFixed(2) + "%",
            ((error.count / stats.totalRuns) * 100).toFixed(2) + "%"
            ])
        : [["No errors recorded"]];

    // Build Error Stats Sheet
    const errorStatsSheet = [
        ...softwareDetailsKeys,  // Software Version Details
        ...softwareDetailsValues,
        ...blankRows,            // Two Blank Rows
        ...softwareStatsHeaders, // Software Stats Table Headers
        ...softwareStatsValues,  // Software Stats Table Values
        ...blankRows,            // Two Blank Rows
        ...errorHeaders,         // Error Stats Table Headers
        ...errorValues           // Error Stats Table Values
    ];

    const ws1 = XLSX.utils.aoa_to_sheet(errorStatsSheet);
    XLSX.utils.book_append_sheet(wb, ws1, "Error Stats");


    // Second sheet - run data
    const runDataSheet = [
        ...softwareDetailsKeys,   // Software Version Details (first 2 rows)
        ...softwareDetailsValues,
        ...blankRows,          //  2 blank rows   
        runHeaders,           // Runs Data Table Headers
        ...runValues          // Runs Data
    ];

    // Create the worksheet and add it to the workbook
    const ws2 = XLSX.utils.aoa_to_sheet(runDataSheet);
    ws2["!cols"] = runHeaders.map((_, index) => ({
        wch: Math.max(...runValues.map(row => (row[index] ? row[index].toString().length : 10)), runHeaders[index].length) + 2
    }));
    addAutoFilter(ws2, softwareDetailsKeys.length + softwareDetailsValues.length + blankRows.length, softwareDetailsKeys.length + softwareDetailsValues.length + blankRows.length + runValues.length, runHeaders.length - 1);
    XLSX.utils.book_append_sheet(wb, ws2, "Run Data");

    const now = new Date(currDate.getTime() - 5 * 60 * 60 * 1000)
    const formattedDate = now.toISOString().slice(0, 19).replace("T", "_").replace(/:/g, "-");
    XLSX.writeFile(wb, `${selectedConfig.name}_${formattedDate}.xlsx`);
};
