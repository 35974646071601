import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TestLogBrowser from './TestLogBrowser';  // <-- Assuming it's in the same directory
import PermMediaIcon from '@mui/icons-material/PermMedia';
import '../styles/TableData.css';

const TestLogBrowserModal = ({ test_series, test_number, id, infdev_sn }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const directoryToFetch = `series-${test_series}/FR-${id}_${test_series}${test_number}_${infdev_sn}`;

    return (
        <>
            <Button type="button" variant="outline-secondary" className="btn btn-square-md data-button" onClick={handleShow}>
                <PermMediaIcon /> 
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Log Browser</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TestLogBrowser instanceId={directoryToFetch} startingDirPath={directoryToFetch} />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TestLogBrowserModal;
