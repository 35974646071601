import React, { useRef, useEffect, useMemo, useCallback } from 'react';

import * as d3 from 'd3';

const TimeSeriesGraph = ({ graphFormat, data, lineConfigs, modalTab, setModalTab, activeImageIndex, setActiveImageIndex }) => {

    const d3Container = useRef(null);
    const balanceVol = useRef(null);
    const measuredVol = useRef(null);

    // Set up SVG and dimensions

    const handleDropClick = (dropIndex) => {
        console.log("Set image index to ", activeImageIndex)
        setActiveImageIndex(dropIndex)
        setModalTab("dropGallery")
    }

    function normalizeData(dataset, yDomain, range) {
        // Find the min and max values in the dataset
        if (range) {
            console.log("Range found for dataset: ", range)
        }
        const minVal = !isNaN(Number(range[0])) ? Number(range[0]) : Math.min(...dataset.map(item => item.value));
        const maxVal = !isNaN(Number(range[1])) ? Number(range[1]) : Math.max(...dataset.map(item => item.value));

        // Define the target range
        const [targetMin, targetMax] = yDomain;

        // Normalize a single value
        const normalize = value => {
            // Avoid division by zero if minVal == maxVal
            if (minVal === maxVal) {
                return targetMin;
            }
            // Apply the normalization formula
            return targetMin + (value - minVal) * ((targetMax / 1.1) - targetMin) / ((maxVal * 1.1) - minVal);
        };

        // Return the dataset with normalized values
        return dataset.map(item => ({
            ...item,
            value: normalize(item.value)
        }));
    }



    function processTestLogsData(logs, config, yDomain = false) {
        const fieldName = config.key;
        let returnSeries = [];

        let previousValue = config.graphType === 'state' ? returnSeries[0]?.value : null;


        logs.forEach((logEntry, index) => {
            const timestamp = new Date(logEntry.event_timestamp); // Convert to Date object
            let value = logEntry.event_data[fieldName];


            returnSeries = returnSeries.filter((item, index) => {
                if (config.graphType === 'state') {
                    if (index === 0 || (item.hasOwnProperty('value') && item.value !== previousValue && item.value !== null)) {
                        previousValue = item.value;
                        return true;  // Include in series if value changes
                    }
                    return false;  // Exclude from series if value doesn't change or is not valid
                }
                return true;  // Include all items for non-state types
            });

            if (config.dataType === "number") {
                value = parseFloat(value);

            }
            if (!isNaN(value) || config.dataType !== "number") {
                    returnSeries.push({ timestamp, value });
            }
        });

        // Normalize data if required
        if (config.normalizeData && yDomain) {
            returnSeries = normalizeData(returnSeries, yDomain, config.range);
        }

        return returnSeries;
    }

    function processDropLogData(logs, config, yDomain = false) {
        console.log("Processing Drop Log Data: ", config.key)

        const fieldName = config.key;
        let returnSeries = [];
        let lowestTimestamp = Infinity;

        // First pass to find the lowest timestamp
        logs.forEach(logEntry => {
            Object.keys(logEntry.event_data).forEach(row_timestamp => {
                const timestampValue = parseFloat(row_timestamp);
                if (timestampValue < lowestTimestamp) {
                    lowestTimestamp = timestampValue;
                }
            });
        });

        // Define startTime based on some external data.test_start_time
        const startTime = new Date(data.test_start_time); // Assume data.test_start_time is defined elsewhere
        const startTimeEpoch = startTime.getTime() / 1000; // Convert startTime to epoch time in seconds
        const offset = startTimeEpoch - lowestTimestamp; // Calculate offset in seconds

        // Second pass to adjust timestamps and collect data
        logs.forEach(logEntry => {
            let parsedLog = logEntry.event_data;

            Object.entries(parsedLog).forEach(([row_timestamp, dropRow]) => {
                // Adjust timestamp by offset
                const adjustedTimestampEpoch = parseFloat(row_timestamp, 10) + offset; // Apply offset
                const timestamp = new Date(adjustedTimestampEpoch * 1000); // Convert to Date object

                let value = dropRow[fieldName]
                value = parseFloat(value);
                console.log(`Found drop log row -> ${timestamp} { ${fieldName}: ${value} } `)

                if (!isNaN(value) || config.dataType !== "number") {
                    returnSeries.push({ timestamp, value });
                }
            });
        });
        
        

        // Normalize data if required
        if (config.normalizeData && yDomain) {
            returnSeries = normalizeData(returnSeries, yDomain, config.range);
        }

        console.log("First few points:", returnSeries.slice(0, 10));
        console.log("Last few points:", returnSeries.slice(-10));
        returnSeries.sort((a, b) => a.timestamp - b.timestamp);

        return returnSeries;
    }

    const processFrameData = (frameData, testStartTime, xScale) => {
        const uniqueEntries = new Map();

        frameData.forEach(function (frame, i) {
            if (!uniqueEntries.has(frame.dropsReported)) {
                const frameTime = new Date(testStartTime.getTime() + (frame.frameNum / 120) * 1000);
                const xPos = xScale(frameTime);
                const dropIndex = i;
                uniqueEntries.set(frame.dropsReported, { ...frame, xPos, dropIndex: dropIndex });
            }
        });

        return Array.from(uniqueEntries.values());
    };
    const processDropData = (dropData, testStartTime, xScale) => {
        const uniqueEntries = new Map();
        let lowestTimestamp = Infinity;

        // First pass to find the lowest timestamp
        dropData.forEach(logEntry => {
            Object.keys(logEntry.event_data).forEach(row_timestamp => {
                const timestampValue = parseInt(row_timestamp, 10);
                if (timestampValue < lowestTimestamp) {
                    lowestTimestamp = timestampValue;
                }
            });
        });

        // Calculate the offset in seconds
        const testStartTimeEpoch = testStartTime.getTime() / 1000; // Convert start time to epoch in seconds
        const offset = testStartTimeEpoch - lowestTimestamp;

        console.log("Starting to process drop data with offset:", offset);

        // Second pass to adjust timestamps and collect data
        dropData.forEach((logEntry, i) => {
            Object.entries(logEntry.event_data).forEach(([row_timestamp, dropRow]) => {
                const adjustedTimestampEpoch = parseInt(row_timestamp, 10) + offset; // Apply offset
                const adjustedTimestamp = new Date(adjustedTimestampEpoch * 1000); // Convert to Date object in milliseconds
                const xPos = xScale(adjustedTimestamp);

                console.log(`Processing log at adjusted timestamp: ${adjustedTimestamp}, xPos: ${xPos}`);

                if (dropRow.hasOwnProperty('volume')) {
                    const volume = parseFloat(dropRow['volume']);
                    if (!isNaN(volume)) {
                        const timestampStr = adjustedTimestamp.toISOString(); // Use ISO string for unique keying

                        if (!uniqueEntries.has(timestampStr)) {
                            console.log(`Adding unique entry at ${timestampStr} with volume ${volume}`);
                            uniqueEntries.set(timestampStr, { xPos, volume });
                        }
                    }
                }
            });
        });

        return Array.from(uniqueEntries.values());
    };




    function drawVerticalLineWithLabel(svg, date, color, label, xScale, yScale, yDomain, labelYPosition) {
        // Draw vertical line
        svg.append('line')
            .attr('x1', xScale(date))
            .attr('y1', yScale(yDomain[0]))
            .attr('x2', xScale(date))
            .attr('y2', 0) // Drawing up to the top of the graph area
            .attr('stroke', color)
            .attr('stroke-width', 2)
            .attr('opacity', 0.5)
            .attr('stroke-dasharray', '5,5');
        // Calculate y-position for the label


        // Draw label at the calculated position
        svg.append('text')
            .attr('x', xScale(date))
            .attr('y', labelYPosition) // Use calculated position
            .attr('fill', color)
            .attr('letter-spacing', function (d, i) { return i * .05 - .1 + "em"; })
            .style("font-size", ".5rem")
            .attr('text-anchor', 'middle') // Center the text around the line
            .text(label);

        // Update the y-position for the next label
    }

    function drawVerticalBox(svg, startDate, endDate, color, opacity, label, xScale, yScale, yDomain, labelYPosition) {
        // Calculate the width of the box based on the xScale
        const boxWidth = xScale(endDate) - xScale(startDate);
    
        // Draw the transparent box
        svg.append('rect')
            .attr('x', xScale(startDate))  // Starting x position
            .attr('y', 0)  // Start from the top of the graph
            .attr('width', boxWidth)  // Width of the box
            .attr('height', yScale(yDomain[0]))  // Height of the box (to the bottom of the graph)
            .attr('fill', '#212529')  // Set the box color
            .attr('fill-opacity', opacity)  // Set transparency for the fill (0 is fully transparent, 1 is fully opaque)
            .attr('stroke', '#212529')  // Optional: outline the box
            .attr('stroke-width', 1)  // Optional: set stroke width
            .attr('stroke-opacity', 0.5);  // Optional: set transparency for the outline

        // Draw label at the calculated position
        svg.append('text')
            .attr('x', (xScale(startDate) + xScale(endDate)) / 2)  // Center the label horizontally in the box
            .attr('y', labelYPosition)  // Use calculated position for the label
            .attr('fill', 'lightgrey')  // Set label color
            .attr('font-size', '.5rem')  // Adjust font size
            .attr('text-anchor', 'middle')  // Center the text horizontally
            .text(label);
    }

    useEffect(() => {
        if (data && d3Container.current) {


            const targetVolume = parseFloat(data.delivery_vtbi)
            const targetRate = parseFloat(data.delivery_rate)
            const maxDrops = Math.max(parseFloat(data.drops_detected_lower), parseFloat(data.drops_detected_upper))
            const targetRuntimeSeconds = targetVolume / targetRate * 3600
            const firstTime = new Date(data.createdAt)
            const startTime = new Date(data.test_start_time)
            const targetEndTime = new Date(startTime.getTime() + targetRuntimeSeconds * 1000);
            const lastTime = new Date(data.test_end_time || data.updatedAt)

            const targetY = graphFormat == "accuracy" ? 0 : targetVolume

            let xTickLabels = [startTime, targetEndTime, lastTime]

            // Clear previous SVG elements
            d3.select(d3Container.current).selectAll("*").remove();

            const svgWidth = d3Container.current.clientWidth;
            const svgHeight = svgWidth * (.25); // 1:4 Aspect Ratio
            const margin = { top: 50, right: 50, bottom: 50, left: 50 };
            const width = svgWidth - margin.left - margin.right;
            const height = svgHeight - margin.top - margin.bottom;

            let svg = d3.select(d3Container.current).select('svg');

            if (svg.empty()) {
                svg = d3.select(d3Container.current)
                    .append('svg')
                    .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
                    .append('g')
                    .attr('transform', `translate(${margin.left},${margin.top})`);
            } else {
                svg.select('g').attr('transform', `translate(${margin.left},${margin.top})`);
            }



            let allTimestamps = [firstTime, lastTime];
            let allValues = [];

            // extract numerical datasets to calculate domain ranges
            lineConfigs.forEach(config => {
                if (config.dataType === "number" && !config.normalizeData) {
                    const processedData = processTestLogsData(data.testLogs, config);
                    allTimestamps.push(...processedData.map(d => d.timestamp));
                    allValues.push(...processedData.map(d => d.value));
                }
            });

            // Calculate domain ranges dynamically based on processed data
            const xDomain = [Math.min(...allTimestamps), Math.max(...allTimestamps) * 1.1];

            const yMin = graphFormat == "accuracy" ? -30 : 0
            const yMax = graphFormat == "accuracy" ? 30 : graphFormat == "drop" ? maxDrops * 1.1 : Math.max(...allValues, targetY) * 1.1
            const yDomain = [yMin, yMax];

            const xScale = d3.scaleTime()
                .domain([new Date(Math.min(...allTimestamps)), new Date(Math.max(...allTimestamps))])
                .range([0, width]);

            const yScale = d3.scaleLinear()
                .domain(yDomain)
                .range([height, 0]);

            // Define the number of ticks for the Y-axis
            const numberOfTicks = Math.min(5, Math.floor(height / 40)); // Adjust the divisor for more or fewer ticks

            // Y-axis with a limited number of ticks
            const yAxis = d3.axisLeft(yScale).ticks(numberOfTicks);
            const yAxisLabel = graphFormat == "delivery" ? "Fluid Volume (mL)" : graphFormat == "accuracy" ? "Percent Error" : graphFormat == "drop" ? "# Drops" : ""
            svg.append("text")
                .attr("transform", "rotate(-90)") // Rotate the text for vertical orientation
                .attr("y", - margin.left) // Position to the left of the Y-axis
                .attr("x", 0 - (height / 2)) // Center the text in the middle of the Y-axis
                .attr("dy", "1em") // Adjust distance from the axis
                .style("text-anchor", "middle") // Center the text alignment
                .attr('fill', "ivory")
                .text(yAxisLabel); // The label text


            const yAxisRight = d3.axisRight(yScale)
                .tickValues([yScale.domain()[0], yScale.domain()[1]]) // Only show 0% and 100%
                .tickFormat(d => d === yScale.domain()[0] ? '0%' : '100%');

            svg.append('g')
                .attr('transform', `translate(${width}, 0)`) // Position it on the right side
                .call(yAxisRight);



            const customTimeFormat = (date) => {
                const diff = (date - xScale.domain()[0]) / 1000; // Difference in seconds
                const hours = Math.floor(diff / 3600);
                const minutes = Math.floor((diff % 3600) / 60);
                const seconds = Math.floor(diff % 60);

                return [hours, minutes, seconds]
                    .map(val => val < 10 ? '0' + val : val) // Add leading zero
                    .join(':');
            };


            const xAxis = d3.axisBottom(xScale).tickValues(xTickLabels).tickFormat(customTimeFormat);


            // Draw the Y Axis
            const yAxisG = svg.append('g').call(yAxis);


            /*
            // Draw horizontal grid lines
                yAxisG.selectAll('.tick').each(function (d, i) {
                    if (d !== 0) {  // Skip the horizontal line at the bottom of the graph
                        const y = yScale(d);
                        svg.append('line')
                            .attr('class', 'grid-line')
                            .attr('x1', 0)
                            .attr('y1', y)
                            .attr('x2', width)
                            .attr('y2', y)
                            .attr('stroke', 'lightgray')
                            .attr('stroke-dasharray', '2,2');
                    }
                });
                
                */

            let labelYPosition = -7;
            let changeLabelPos = true;


            // Define the clipping path to stop lines printing outside the plot area
            const defs = svg.append("defs")

            const mask = defs.append("mask")
                .attr("id", "graphMask")

            mask.append('rect')
                .attr('fill', "ivory")
                .attr("width", width)
                .attr("height", height);


            // Draw the data lines
            lineConfigs.forEach((config, index) => {

                const processedData = (config.eventName == "drop_log" ? processDropLogData(data.dropLogs, config, yDomain) : processTestLogsData(data.testLogs, config, yDomain))
                
                if (config.graphType === 'state') {
                    // Draw vertical line with label for each state change
                    processedData.forEach(dataPoint => {
                        if (dataPoint.value && dataPoint.value.toString() && !config.ignoreValues.includes(dataPoint.value.toString())) {
                            if (config.key == "calculated_t0") {
                                const t0_calc_min = Math.floor((dataPoint.value % 3600) / 60);
                                const t0_calc_sec = Math.floor(dataPoint.value % 60);
                                const t0_calc = `${t0_calc_min.toString().padStart(2, '0')}:${t0_calc_sec.toString().padStart(2, '0')}`;

                                drawVerticalLineWithLabel(svg, new Date(dataPoint.timestamp), config.color, "T₀ (Calc): " + t0_calc + "s", xScale, yScale, yDomain, labelYPosition, index);
                            } else {
                                drawVerticalLineWithLabel(svg, new Date(dataPoint.timestamp), config.color, dataPoint.value.toString(), xScale, yScale, yDomain, labelYPosition, index);
                            }
                        }
                        if (dataPoint.value && dataPoint.value.toString() && config.ignoreValues.includes(dataPoint.value.toString())) {
                            changeLabelPos = false;
                        }
                    })
                    if (changeLabelPos == true) {
                        labelYPosition -= 10
                    }
                    changeLabelPos = true
                } else if (config.graphType === 'greyout') {
                    //grey out T0 and AD
                    if (data.t0_time_secs != null && data.test_time_elapsed_seconds > data.t0_time_secs) {
                        drawVerticalBox(svg, new Date(xScale.domain()[0]), new Date(new Date(data.test_start_time).getTime() + data.t0_time_secs*1000), config.color, config.opacity, "T₀", xScale, yScale, yDomain, yScale(yDomain[0]) + 10, index);
                    }
                    if (data.ad_start_time_secs != null && data.ad_end_time_secs != null && data.test_time_elapsed_seconds > data.ad_end_time_secs) {
                        drawVerticalBox(svg, new Date(new Date(data.test_start_time).getTime() + data.ad_start_time_secs*1000), new Date(new Date(data.test_start_time).getTime() + data.ad_end_time_secs*1000), config.color, config.opacity, "AD", xScale, yScale, yDomain, yScale(yDomain[0]) + 10, index);
                    }

                    //label Tb and Te
                    if (data.tb_start_time_secs != null && data.tb_end_time_secs != null && data.test_time_elapsed_seconds > data.tb_end_time_secs) {
                        drawVerticalBox(svg, new Date(new Date(data.test_start_time).getTime() + data.tb_start_time_secs*1000), new Date(new Date(data.test_start_time).getTime() + data.tb_end_time_secs*1000), config.color, '0', "Tb", xScale, yScale, yDomain, yScale(yDomain[0]) + 10, index);
                    }
                    if (data.te_start_time_secs != null && data.te_end_time_secs != null && data.test_time_elapsed_seconds > data.te_end_time_secs) {
                        drawVerticalBox(svg, new Date(new Date(data.test_start_time).getTime() + data.te_start_time_secs*1000), new Date(new Date(data.test_start_time).getTime() + data.te_end_time_secs*1000), config.color, '0', "Te", xScale, yScale, yDomain, yScale(yDomain[0]) + 10, index);
                    }

                    //draw lines to show different test periods
                    if (data.t0_time_secs != null) {
                        const t0_user_min = Math.floor((data.t0_time_secs % 3600) / 60);
                        const t0_user_sec = Math.floor(data.t0_time_secs % 60);
                        const t0_user = `${t0_user_min.toString().padStart(2, '0')}:${t0_user_sec.toString().padStart(2, '0')}`;

                        drawVerticalLineWithLabel(svg, new Date(new Date(data.test_start_time).getTime() + data.t0_time_secs*1000), config.color, "T₀ (User): " + t0_user + "s", xScale, yScale, yDomain, labelYPosition, index);
                        labelYPosition -= 10
                    }
                    if (data.ad_start_time_secs != null && data.test_time_elapsed_seconds > data.ad_start_time_secs) {
                        drawVerticalLineWithLabel(svg, new Date(new Date(data.test_start_time).getTime() + data.ad_start_time_secs*1000), config.color, "" , xScale, yScale, yDomain, labelYPosition, index);
                    }
                    if (data.te_start_time_secs != null && data.test_time_elapsed_seconds > data.te_start_time_secs) {
                        drawVerticalLineWithLabel(svg, new Date(new Date(data.test_start_time).getTime() + data.te_start_time_secs*1000), config.color, "", xScale, yScale, yDomain, labelYPosition, index);
                    }
                } else {
                    // Draw line for non-state types
                    const line = d3.line()
                        .x(d => xScale(d.timestamp))
                        .y(d => yScale(d.value));

                    svg.append('path')
                        .datum(processedData)
                        .attr('fill', 'none')
                        .attr('stroke', config.color)
                        .attr('stroke-width', 2)
                        .attr('opacity', config.opacity)
                        .attr('d', line)
                        .attr("mask", "url(#graphMask)");

                    if(config.key == "balance_ml"){
                        balanceVol.current = processedData;
                    } else if  (config.key == "delivery_vol_delivered_raw") {
                        measuredVol.current = processedData;
                    }
                }
            });

            // Add the X Axis
            svg.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(xAxis);

            // Add the Y Axis
            svg.append('g')
                .call(d3.axisLeft(yScale));

            // Draw horizontal dotted line at targetY on Y-axis
            svg.append('line')
                .attr('x1', xScale(xDomain[0]))
                .attr('y1', yScale(targetY))
                .attr('x2', xScale(lastTime))
                .attr('y2', yScale(targetY))
                .attr('stroke', 'green')
                .attr('stroke-width', 2)
                .attr('opacity', 0.5)
                .attr('stroke-dasharray', '5,5');

            if (graphFormat == "delivery") {
                // Draw diagonal dotted line from (0,0) to the intersection
                svg.append('line')
                    .attr('x1', xScale(startTime))
                    .attr('y1', yScale(0))
                    .attr('x2', xScale(targetEndTime))
                    .attr('y2', yScale(targetY))
                    .attr('stroke', 'green')
                    .attr('stroke-width', 2)
                    .attr('opacity', 0.5)
                    .attr('stroke-dasharray', '5,5');
            }

            // Draw vertical dotted line at targetRuntimeMin on X-axis
            svg.append('line')
                .attr('x1', xScale(targetEndTime))
                .attr('y1', yScale(yDomain[0]))
                .attr('x2', xScale(targetEndTime))
                .attr('y2', yScale(yDomain[1]))
                .attr('stroke', 'green')
                .attr('stroke-width', 2)
                .attr('opacity', 0.5)
                .attr('stroke-dasharray', '5,5');

            const processedFrameData = processFrameData(data.frameData, new Date(data.test_start_time), xScale);
            const processedDropData = processDropData(data.dropLogs, new Date(data.test_start_time), xScale)

            const dropElements = svg.selectAll('.drop-element')
                .data(processedDropData)
                .enter()
                .append('path') // Use path instead of rect
                .attr('class', 'drop-element')
                .attr('d', d => {
                    // fluid drop shape
                    const x = d.xPos + 5; // Adjust to position the drop based on xScale
                    const y = height + 20; // Adjust for desired y position
                    return `M${x},${y} Q${x - 10},${y + 15} ${x},${y + 15} Q${x + 10},${y + 15} ${x},${y} Z`;
                })
                .attr('fill', 'transparent')
                .attr('stroke', 'dimgray')
                .attr('stroke-width', 1);

            const frameElements = svg.selectAll('.frame-element')
                .data(processedFrameData)
                .enter()
                .append('path') // Use path instead of rect
                .attr('class', 'frame-element')
                .attr('d', d => {
                    // fluid drop shape
                    const x = d.xPos + 5; // Adjust to position the drop based on xScale
                    const y = height + 20; // Adjust for desired y position
                    return `M${x},${y} Q${x - 10},${y + 15} ${x},${y + 15} Q${x + 10},${y + 15} ${x},${y} Z`;
                })
                .attr('fill', 'steelblue')
                .attr('stroke', 'steelblue')
                .attr('stroke-width', 0)
                .on("mouseover", function (d) {
                    d3.select(this).attr("fill", "cyan");
                })
                .on("mouseout", function (d) {
                    d3.select(this).attr("fill", "steelblue");
                })
                .on('click', (event, d) => {
                    handleDropClick(d.dropIndex);
                    console.log('Clicked:', d);
                });

            // Create vertical and horizontal line and label elements
            const focusVerticalLine = svg.append('line')
                .attr('class', 'focusVerticalLine')
                .attr('stroke', 'white')
                .attr('stroke-width', 1.5)
                .style('display', 'none');

            const focusHorizontalLine = svg.append('line')
                .attr('class', 'focusHorizontalLine')
                .attr('stroke', 'white')
                .attr('stroke-width', 1.5)
                .style('display', 'none');

            const focusLabelRect = svg.append('rect')
                .attr('class', 'focusLabelRect')
                .attr('fill', 'white')
                .attr('stroke', 'black')
                .attr('stroke-width', 1.5)
                .attr('rx', 1) // rounded corners
                .attr('ry', 1)
                .style('display', 'none');

            const focusLabel = svg.append('text')
                .attr('class', 'focusLabel')
                .attr('fill', 'black')  // Set the text color to black
                .attr('font-size', '12px')  // Set the text size to smaller
                .attr('text-anchor', 'middle')
                .attr('dy', '-1em')
                .style('display', 'none');

            // Add overlay for capturing mouse events
            svg.append('rect')
                .attr('class', 'overlay')
                .attr('width', width)
                .attr('height', height)
                .style('fill', 'none')
                .style('pointer-events', 'all')
                .on('mouseover', () => {
                    focusVerticalLine.style('display', null);
                    focusHorizontalLine.style('display', null);
                    focusLabel.style('display', null);
                })
                .on('mouseout', () => {
                    focusHorizontalLine.style('display', 'none');
                    focusVerticalLine.style('display', 'none');
                    focusLabel.style('display', 'none');
                    focusLabelRect.style('display', 'none');
                })
                .on('mousemove', function (event) {
                    const [mouseX, mouseY] = d3.pointer(event);
                    const x0 = xScale.invert(mouseX);  // Get the X value (time) from the mouse position
                    const y0 = yScale.invert(mouseY);
                    const timeDifferenceInSeconds = (x0 - xScale.domain()[0]) / 1000; // Difference in seconds

                    // Calculate hours, minutes, and seconds
                    const hours = Math.floor(timeDifferenceInSeconds / 3600);
                    const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
                    const seconds = Math.floor(timeDifferenceInSeconds % 60);

                    // Format the time string (omit hours if 0)
                    const formattedTime = `${hours > 0 ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                    focusVerticalLine
                        .attr('x1', mouseX)
                        .attr('y1', 0)
                        .attr('x2', mouseX)
                        .attr('y2', height);

                    focusLabel
                        .attr('x', mouseX > width / 2 ? mouseX - 35 : mouseX + 35)
                        .attr('y', mouseY)
                        .text((formattedTime));
                

                    let nearestBalanceVolPoint = null;
                    let nearestMeasuredVolPoint = null;
                
                    // Check if balanceVol is valid (not null/undefined and not empty)
                    if (Array.isArray(balanceVol.current) && balanceVol.current.length > 0) {
                        nearestBalanceVolPoint = findNearestPoint(balanceVol.current, x0);
                    }
                
                    // Check if measuredVol is valid (not null/undefined and not empty)
                    if (Array.isArray(measuredVol.current) && measuredVol.current.length > 0) {
                        nearestMeasuredVolPoint = findNearestPoint(measuredVol.current, x0);
                    }
                
                    // Always show the vertical line at the mouse's X position
                    
                    // Define proximity threshold (in pixels) for showing the horizontal lines
                    const proximityThreshold = 10;
                
                    // Track if we are displaying a horizontal line (if close to any point)
                    let showHorizontalLine = true;
                    let nearestPoint = null;

                    if (nearestBalanceVolPoint && nearestMeasuredVolPoint) {
                        if (Math.abs(mouseY - yScale(nearestBalanceVolPoint.value)) < Math.abs(mouseY - yScale(nearestMeasuredVolPoint.value))) {
                            nearestPoint = nearestBalanceVolPoint;
                            focusLabelRect.attr('stroke', 'red');
                        } else {
                            nearestPoint = nearestMeasuredVolPoint;
                            focusLabelRect.attr('stroke', 'blue');
                        }
                    } else if (nearestBalanceVolPoint) {
                        nearestPoint = nearestBalanceVolPoint;
                        focusLabelRect.attr('stroke', 'red');
                    } else if (nearestMeasuredVolPoint) {
                        nearestPoint = nearestMeasuredVolPoint;
                        focusLabelRect.attr('stroke', 'blue');
                    } else {
                        showHorizontalLine = false;
                        focusLabelRect.attr('stroke', 'black');
                    }
                    
                    if (nearestPoint != null && Math.abs(mouseY - yScale(nearestPoint.value)) > proximityThreshold) {
                        nearestPoint = null;
                        showHorizontalLine = false;
                    }

                    if (nearestPoint != null && showHorizontalLine) {
                        const nearestX = xScale(nearestPoint.timestamp);
                        const nearestY = yScale(nearestPoint.value);
                
                        focusHorizontalLine
                            .attr('x1', 0)
                            .attr('y1', nearestY)
                            .attr('x2', width)
                            .attr('y2', nearestY)
                            .style('display', null);  // Show horizontal line
            
                        focusLabel
                            .attr('x', nearestX > width / 2 ? nearestX - 35 : nearestX + 35)
                            .attr('y', nearestY)
                            .text(`${nearestPoint.timestamp.toLocaleTimeString()}, ${nearestPoint.value.toFixed(2)}mL`)
            
                    }
                
                    // If no horizontal line should be shown (mouse is not close to any points), hide it
                    if (!showHorizontalLine) {
                        focusHorizontalLine.style('display', 'none');
                    }
                
                    // Update the focusLabelRect to match the text size, if label is displayed
                    const textBBox = focusLabel.node().getBBox();
                    focusLabelRect
                        .attr('x', textBBox.x - 5)
                        .attr('y', textBBox.y - 2)
                        .attr('width', textBBox.width + 10)
                        .attr('height', textBBox.height + 4)
                        .style('display', null);


                    focusLabel.style('display', null);
                });
        }
            /**
             * Helper function to find the nearest point in a data array based on a given x value (timestamp)
             */
            function findNearestPoint(dataArray, xValue) {
                let nearestPoint = dataArray[0];
                let minDistance = Math.abs(nearestPoint.timestamp - xValue);
            
                for (let i = 1; i < dataArray.length; i++) {
                    const distance = Math.abs(dataArray[i].timestamp - xValue);
                    if (distance < minDistance) {
                        nearestPoint = dataArray[i];
                        minDistance = distance;
                    }
                }
            
                return nearestPoint;
            }

        

    }, [data, lineConfigs, modalTab, activeImageIndex]); // Redraw graph when data changes

    return (
        <div ref={d3Container} style={{ width: '100%', margin: "0" }} />
    );
};

export default TimeSeriesGraph;
