import React from 'react';
import '../../styles/TableData.css';

const ImageMetadataOverlay = ({ data }) => {

    const findMedianBrightness = counts => {
        const total = counts.reduce((acc, val) => acc + val, 0);
        const medianPos = Math.floor((total + 1) / 2);
        let accumulated = 0;
      
        for (let i = 0; i < counts.length; i++) {
          accumulated += counts[i];
          if (accumulated >= medianPos) return i;
        }
      
        return -1; // In case the array is empty or all zeroes
      };
      

    return (
        <div className="dropframe-metadata">
            <span><strong>Num Drops:</strong> {data.numDrops}</span>
            <span><strong>Total Volume</strong> {data.totalVolume}</span>
            <span><strong>xDropFormer:</strong> {data.xDropFormer}</span>
            <span><strong>yDropFormer:</strong> {data.yDropFormer}</span>
            <span><strong>xWaterLevel:</strong> {data.xWaterLevel}</span>
            <span><strong>Pitch Angle:</strong> {data.pitchAngle}</span>
            <span><strong>Roll Angle:</strong> {data.rollAngle}</span>
            {data.histogram && data.histogram.length > 1 ? <span><strong>Median Pixel Value:</strong>{findMedianBrightness(data.histogram)}</span> : ""}
            </div>
    );
};

export default ImageMetadataOverlay;
