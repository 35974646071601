import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';

const CommandHistory = ({ data }) => {


    function CommandHistoryGrid({ data }) {
        const isDataLoaded = data !== undefined && data.length > 0;
        console.log("commandHistory: ", data)
        if (isDataLoaded) {
            return (
            <div className="command-history-container">
                {data.map((entry) => (
                <div key={entry.id} id={`command-${entry.id}`} className="command-entry">
                    <h3 className="timestamp">{entry.event_timestamp}</h3>
                    <div className="command-results">
                    {entry.event_data.command_result.map((result, index) => (
                        <div key={index} className="command-column">
                        <p className="cmd">{result.cmd}</p>
                        <p className="output">{result.raw_output}</p>
                        </div>
                    ))}
                    </div>
                </div>
                ))}
            </div>
            );
        } else {
            return <p> Loading ...</p>
        }
                
      }
      

    return (
        <div style={{marginTop: "1rem"}}>
            {CommandHistoryGrid(data)}
        </div>
    );
};

export default CommandHistory;
