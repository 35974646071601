
import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from "react-bootstrap/Image";
import '../styles/Nav.css'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

import logoWhite from "../images/amgis-logo-white.png";

const mode = process.env.REACT_APP_MODE;
// Check if REACT_APP_MODE is set to 'development'
const debugMode = mode === "development";

export const MainNavBar = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    return (

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="header-logo mainnav">
            <Container fluid>
                <Navbar.Brand href="#home" className="d-flex align-items-center">
                    <Image src={logoWhite} alt="Amgis" className="border-right mx-4 pr-4" style={{ height: "3.5rem" }} />
                    <h2 style={{ fontSize: "1.5rem" }}>Test Analysis Platform</h2></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    {((isAuthenticated && (accounts && accounts[0] && accounts[0].name)) || debugMode) ?
                        <>
                            <Nav className="justify-content-end flex-grow-1 pr-5">
                                <Nav.Link href="/">Test Data</Nav.Link>
                                <Nav.Link href="/control-panel">Control Panel</Nav.Link>
                                <NavDropdown title="Software Management" id="collasible-nav-dropdown">
                                    <NavDropdown.Item href="/mender-artifact-generator">Mender Artifact Generator</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                </NavDropdown>
                            </Nav>
                            <Nav className="justify-content-end pr-5">
                                <Navbar.Text className="border-left px-4">
                                    {debugMode ? "Development Mode" : accounts[0].name}
                                </Navbar.Text>
                                <SignOutButton />
                            </Nav>
                        </>
                        :
                        <Nav className="justify-content-end pr-5"><SignInButton /> </Nav>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
