import React, { useEffect, useState } from 'react';
import { getSeparatedTestStats, getFilteredTestData } from '../../services/apiService.js';
import * as XLSX from 'xlsx';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import DownloadIcon from '@mui/icons-material/Download';

const TestDataStatsBar = ({ testStats, testFilter, setTestFilter }) => {

    const [totalError, setTotalError] = useState(false)
    const [measError, setMeasError] = useState(false)
    const [noFlow, setNoFlow] = useState(false)
    const [boundaryRatio, setBoundaryRatio] = useState(false)
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const [sepTestStats, setSepTestStats] = useState({});
    const currDate = new Date();

    useEffect(() => {
        if (testFilter.test_start_date) {
            setCustomStartDate(new Date(testFilter.test_start_date));
        }  else if(testFilter.startDateOffset) {
            let startDate = new Date(currDate);
            startDate.setDate(startDate.getDate() - testFilter.startDateOffset);
            setCustomStartDate(startDate);
        } else {
            let startDate = new Date(currDate.getTime() - 24 * 60 * 60 * 1000);
            setCustomStartDate(startDate);
        }

        if (testFilter.test_end_date) {
            setCustomEndDate(new Date(testFilter.test_end_date));
        } else {
            setCustomEndDate(currDate);
        }
    }, [testFilter.test_start_date, testFilter.test_end_date]);

    useEffect(() => {
        console.log("Start, End: ", customStartDate, customEndDate)
    }, [customStartDate]);

    useEffect(() => {

        let newTotalError = testStats.total_error_percent || false
        let newMeasError = testStats.measurement_error_percent || false
        let newNoFlow = testStats.max_noflow_time_seconds || false
        let newBoundaryRatio = testStats.droplog_boundary_ratio_avg || false

        setTotalError(newTotalError)
        setMeasError(newMeasError)
        setNoFlow(newNoFlow)
        setBoundaryRatio(newBoundaryRatio)

    }, [testStats]);

    const unitMap = {
        total_error_percent: '%',
        measurement_error_percent: '%',
        drops_vol_avg: 'mL',
        droplog_boundary_ratio_avg: '',
        max_noflow_time_seconds: ''
    }

    const renderStats = (colName, colTitle, data) => {

        const unit = unitMap[colName] || ''

        const min = data.min ? `${data.min}${unit}` : ''
        const max = data.max ? `${data.max}${unit}` : ''
        const avg = data.avg ? `${data.avg}${unit}` : ''
        const med = data.med ? `${data.med}${unit}` : ''
        const stdDev = !isNaN(Number(data.stdDev)) ? `${2 * Number(data.stdDev)}${unit}` : ''
        const quartileCounts = data.quartileCounts ? `${data.quartileCounts}${unit}` : ''

        return (

            <span>
                <strong className="stats-title">{colTitle}</strong> {`[ `}
                <span className="stats-value">
                    {min}</span> , <span className="stats-value">{max}</span> {` ] `}
                <span className="stats-symbol">x̄</span> <span className="stats-value" title="Sample Mean">{avg}</span>
                <span className="stats-symbol" title="Standard Deviation">{`2σ`} </span><span className="stats-value" title="2 * Sample Standard Deviation">{stdDev}</span>
            </span>

        );
    };

    const handleDownloadCombinedStats = async () => {
        try {
            const params = {
                startTime: customStartDate,
                endTime: customEndDate
            };

            console.log('Request Params:', params);

            const response = await getSeparatedTestStats(params);
            console.log('API Response:', response);

            // Flatten the response data
            const dataToExport = flattenData(response);
            console.log('Data to export:', dataToExport);

            if (!Array.isArray(dataToExport) || dataToExport.length === 0) {
                throw new Error('Invalid data format or no data available');
            }

            // Convert data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Stats");

            // Make the header row sticky and enable filtering
            worksheet['!autofilter'] = { ref: "A1:Z1" }; // Adjust the range as needed

            // Create a buffer from the workbook
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the buffer
            const blob = new Blob([buffer], { type: 'application/octet-stream' });

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'stats.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating Excel file:', error.message);
        }
    };

    const handleDownloadTestRunData = async () => {
        try {
            const params = {
                startTime: customStartDate,
                endTime: customEndDate,
                ...testFilter // Apply all current filters
            };
    
            console.log('Fetching test run data with params:', params);
    
            // Fetch test run data from the API
            const response = await getFilteredTestData(params);
            console.log('API Response:', response);
    
            if (!response || Object.keys(response).length === 0) {
                throw new Error('No test run data available.');
            }
    
            // Convert data to a row-per-test format
            const dataToExport = formatTestRunData(response);
            console.log('Formatted Test Run Data:', dataToExport);
    
            if (!Array.isArray(dataToExport) || dataToExport.length === 0) {
                throw new Error('Invalid formatted data or no data available.');
            }
    
            // Convert data to an Excel sheet
            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Test Runs");
    
            // Enable autofilter for better readability
            worksheet['!autofilter'] = { ref: "A1:Z1" }; // Adjust column range if needed
    
            // Create an Excel file and trigger download
            const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
    
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            const now = new Date(currDate.getTime() - 5 * 60 * 60 * 1000)
            const formattedDate = now.toISOString().slice(0, 19).replace("T", "_").replace(/:/g, "-");
            link.download = `test_run_data_${formattedDate}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
        } catch (error) {
            console.error('Error generating Test Run Data Excel file:', error.message);
        }
    };    

    const formatTestRunData = (testData) => {
        return testData.data.map((test) => ({
            test_start_time: test.test_start_time ? new Date(test.test_start_time).toISOString() : '', // Test Start Time
            test_id: test.id || '', // Unique Test ID
            serial_number: test.infdev_sn || '', // Serial Number
            test_set_number: test.test_set_number || '', 
            rate: test.delivery_rate || '', 
            vtbi: test.delivery_vtbi || '',
            delivery_vol_delivered: test.delivery_vol_delivered || '',
            balance_ml: test.actual_vol_delivered_ml || '',
            needle_gauge: test.needle_gauge || '',
            fluid_type: test.fluid_type || '',
            alarm_status: test.alarm_status || '',
            error_code: test.errorcode || '',
            stm8_pri_fw_build: test.stm8_pri_fw_build || '',
            stm8_sec_fw_build: test.stm8_sec_fw_build || '',
            imx7_m4_version: test.imx7_m4_version || '',
            stm32_m4_version: test.stm32_m4_version || '',
            a7_sw_version: test.a7_sw_version || '',
            fcl_version: test.fcl_version || '',
            test_time_elapsed_seconds: test.test_time_elapsed_seconds || '',
            measurement_error_percent: test.measurement_error_percent || '',
            total_error_percent: test.total_error_percent || '',
            droplog_boundary_ratio_avg: test.droplog_boundary_ratio_avg || '',
            drops_vol_last: test.drops_vol_last || '', 
            drops_vol_avg: test.drops_vol_avg || '', 
            drops_delivered: test.drops_delivered || '', 
            drops_calculated: test.actual_vol_delivered_ml/test.drops_vol_avg*1000 || '',
            drops_detected_upper: test.drops_detected_upper || '', 
            error_drops_detected_upper: (test.drops_detected_upper-(test.actual_vol_delivered_ml/test.drops_vol_avg*1000))/(test.actual_vol_delivered_ml/test.drops_vol_avg*1000)*100 || '',
            drops_detect2: test.drops_detect2|| '', 
            error_drops_detect2: (test.drops_detect2-(test.actual_vol_delivered_ml/test.drops_vol_avg*1000))/(test.actual_vol_delivered_ml/test.drops_vol_avg*1000)*100 || '',
            drops_measured: test.drops_measured || '', 
            error_drops_measured: (test.drops_measured-(test.actual_vol_delivered_ml/test.drops_vol_avg*1000))/(test.actual_vol_delivered_ml/test.drops_vol_avg*1000)*100 || '',
            drops_detected_lower: test.drops_detected_lower || '', 
            error_drops_detected_lower: (test.drops_detected_lower-(test.actual_vol_delivered_ml/test.drops_vol_avg*1000))/(test.actual_vol_delivered_ml/test.drops_vol_avg*1000)*100 || ''
        }));
    };

    // Function to flatten the JSON structure
    const flattenData = (data) => {
        const result = [];

        Object.keys(data).forEach(testName => {
            const [serialNumber, rate, ...fluidArray] = testName.split('-');
            const fluid = fluidArray.join('-');

            const metrics = data[testName];
            Object.keys(metrics).forEach(metricName => {
                const metricValues = metrics[metricName];
                const flatObject = {
                    testName,      // Include the original testName
                    serialNumber,
                    rate,
                    fluid,
                    metricName,
                    ...metricValues,
                    ...metricValues.quartileCounts
                };
                delete flatObject.quartileCounts;
                result.push(flatObject);
            });
        });

        return result;
    };


    return (
        <div className="stats-bar-container" >
            {totalError ? renderStats("total_error_percent", "Total Error", totalError) : <></>}
            {measError ? renderStats("measurement_error_percent", "Meas Error", measError) : <></>}
            {noFlow ? renderStats("max_noflow_time_seconds", "Longest NoFlow", noFlow) : <></>}
            {boundaryRatio ? renderStats("droplog_boundary_ratio_avg", "Boundary Ratio Avg", boundaryRatio) : <></>}
            <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="secondary" size="sm" style={{ padding: '0.25rem 0.25rem', fontSize: '0.5rem' }}>
                    <DownloadIcon style={{ fontSize: '1rem', height: '1rem', width: '1rem' }}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleDownloadCombinedStats}>Combined Stats</Dropdown.Item>
                    <Dropdown.Item onClick={handleDownloadTestRunData}>Test Run Data</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>

    );
};

export default TestDataStatsBar;
