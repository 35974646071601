import React, { useState } from 'react';
import '../../styles/TableData.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { postTestRunUpdateMarkup } from '../../services/apiService.js';
import EditIcon from '@mui/icons-material/Edit';

const TestMarkupModal = ({ data }) => {


    const trId=data.id
    const testNumber=data.test_number

    const [show, setShow] = useState(false);
    const [excludeFlag, setExcludeFlag] = useState(data.exclude_flag);
    const [postTestNote, setPostTestNote] = useState(data.post_test_note);
    const [testSetId, setTestSetId] = useState(data.testSetId)
    
    async function postMarkupChange(updateData) {
        try {
            const res = await postTestRunUpdateMarkup({ tr_id: trId, ...updateData });
            console.log('API Data:', res);
            setShow(false)
        } catch (error) {
            console.error('Error Posting API Update:', error);
        }
    }

    const handleSubmitExclusion = (e) => {
        e.preventDefault();
        console.log(`Attempting to exclude ${trId} ...`, excludeFlag);
        postMarkupChange({ exclude_flag: excludeFlag });
        
    };

    const handleSubmitTestSet = (e) => {
        e.preventDefault();  
        postMarkupChange({ testSetId: testSetId });
    };


    const handleSubmitNote = (e) => {
        e.preventDefault();  
        postMarkupChange({ post_test_note: postTestNote });
    };

    const handleClearExclusion = () => {
        postMarkupChange({ exclude_flag: null });
    };

    const handleClearNote = () => {
        postMarkupChange({ post_test_note: null });
    };

    const handleExclusionInputChange = (e) => {
        setExcludeFlag(e.target.value);
    };


    const handleTestSetInputChange = (e) => {
        setTestSetId(e.target.value);
    };


    const handleNoteInputChange = (e) => {
        setPostTestNote(e.target.value);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return (
        <>
            <Button type="button" variant="outline-secondary" className="btn btn-square-md data-button" onClick={handleShow}>
                <EditIcon />
            </Button>

            <Modal show={show} onHide={handleClose} backdrop={true}>
                <Modal.Body>
                    <div style={{width: "100%"}}>
                        <strong>Exclude Test Number {trId}-{testNumber} From Results</strong>
                        {data.exclude_flag != null ? (
                            <>
                                <p>{data.exclude_flag}</p>
                                <Button variant="danger" onClick={handleClearExclusion}>Clear Exclusion</Button>
                            </>
                        ) : (
                            
                            <form onSubmit={handleSubmitExclusion}>
                                <input type="text" value={excludeFlag} onChange={handleExclusionInputChange} placeholder="Reason for Exclusion" />
                                <Button type="submit" variant="danger" disabled={!excludeFlag}>Exclude</Button>
                            </form>
                        )}
                    </div>
                    <div style={{minHeight: "10rem", marginTop: '20px' }}>
                        <strong>Post Test Note</strong>
                        {data.post_test_note != null ? (
                            <>
                                <p>{data.post_test_note}</p>
                                <Button variant="secondary" onClick={handleClearNote}>Clear Note</Button>
                            </>
                        ) : (
                            <form onSubmit={handleSubmitNote}>
                                <div style={{minHeight: "10rem", width: "100%", overflowY: "scroll"}}>
                                    <textarea value={postTestNote} onChange={handleNoteInputChange} placeholder="Post Test Note"></textarea>
                                </div>
                                <Button type="submit" variant="secondary" disabled={!postTestNote}>Submit Note</Button>
                            </form>
                        )}
                    </div>
                   
                        { !isNaN(testSetId) ? 
                         <div style={{ marginTop: '20px' }}>
                                <p><strong>Test Set:</strong>{data.test_set_number}  ⟨ID# {data.testSetId} ⟩ </p>
                            <form onSubmit={handleSubmitTestSet}>
                                    <input type="text" value={testSetId} onChange={handleTestSetInputChange} placeholder={testSetId} />
                                <Button type="submit" variant="danger" disabled={testSetId === data.testSetId}>Change Test Set ID #</Button>
                            </form>
                        </div>
                         : <></>} 
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>Close Edit View</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TestMarkupModal;
