import React, { useState, useEffect } from "react";
import { createSoftwareConfig } from "../../services/apiService.js";

const CreateSoftwareConfigPopup = ({ showPopup, setShowPopup, newConfig, setNewConfig, versionMapping, existingConfigs, refreshConfigs }) => {
  if (!showPopup) return null;

  const [configRows, setConfigRows] = useState([
    { versionType: "", selectedVersion: "" },
  ]);

  const handleAddRow = () => {
    // Get already selected version types
    const usedVersionTypes = configRows.map(row => row.versionType);
  
    // Find available version types that are NOT already used
    const availableVersionTypes = Object.keys(versionMapping).filter(
      (version) => !usedVersionTypes.includes(version)
    );
  
    if (availableVersionTypes.length === 0) {
      return; // Prevent adding a new row if no available types left
    }
  
    setConfigRows([...configRows, { versionType: "", selectedVersion: "" }]);
  };

  const handleDeleteRow = (index) => {
    setConfigRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };

  const handleCreateConfig = async () => {
    // Filter out rows where versionType OR selectedVersion is blank
    const validConfigs = configRows.filter(row => row.versionType && row.selectedVersion);
  
    // Ensure at least one valid configuration is provided
    if (validConfigs.length === 0) {
      alert("You must provide at least one software version constraint.");
      return;
    }
  
    // Ensure name and universion are not already used
    if (existingConfigs.some(config => config.name === newConfig.name)) {
      alert("The provided name is already in use. Please choose a different name.");
      return;
    }
    if (existingConfigs.some(config => config.universion === newConfig.universion)) {
      alert("The provided universion is already in use. Please choose a different universion.");
      return;
    }
  
    // Convert array into an object { versionType1: selectedVersion, versionType2: selectedVersion, ... }
    const formattedConfig = validConfigs.reduce((acc, config) => {
      acc[config.versionType] = config.selectedVersion;
      return acc;
    }, {});

    // Check for an existing configuration that matches the software combination
    const isDuplicate = existingConfigs.some(existingConfig => {
      // Extract only the software version mappings (excluding name and universion)
      const existingSoftwareConfig = { ...existingConfig };
      delete existingSoftwareConfig.name;
      delete existingSoftwareConfig.universion;
      delete existingSoftwareConfig.createdAt;
      delete existingSoftwareConfig.updatedAt;
      delete existingSoftwareConfig.usedFirst;
      delete existingSoftwareConfig.usedLast;
      delete existingSoftwareConfig.id;

      // Get keys (version types) for both configs
      const existingKeys = Object.keys(existingSoftwareConfig);
      const newKeys = Object.keys(formattedConfig);

      // Ensure the new config includes exactly the same version types as the existing one
      if (
        existingKeys.length !== newKeys.length || 
        existingKeys.some(key => !newKeys.includes(key)) || 
        newKeys.some(key => !existingKeys.includes(key))
      ) {
        return false; // Not a match if the version types are different
      }

      // Ensure all version mappings match exactly
      return existingKeys.every(key => existingSoftwareConfig[key] === formattedConfig[key]);
    });

    if (isDuplicate) {
      alert("A software configuration with the same version constraints already exists.");
      return;
    }
  
    // Add the name and universion to the formatted object
    formattedConfig["name"] = newConfig.name;
    formattedConfig["universion"] = newConfig.universion;

    
    console.log("Final API Payload:", JSON.stringify(formattedConfig, null, 2)); // Debugging output
  
    try {
      await createSoftwareConfig(formattedConfig); // Send formatted object to API
      alert("Software configuration created successfully!");
      setShowPopup(false); // Close popup on success
      setConfigRows([{ versionType: "", selectedVersion: "" }]); // Reset form
      setNewConfig({ name: "", universion: "" }); // Reset name and universion
      refreshConfigs();
    } catch (error) {
      console.error("Error creating software config:", error);
      alert("Failed to create software configuration.");
    }
  };
  
  return (
  <div
    className="popup-overlay"
    onClick={() => setShowPopup(false)}
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  >
    <div
      className="popup-content"
      onClick={(e) => e.stopPropagation()}
      style={{
        position: "relative",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <h4 style={{ marginTop: "20px", marginBottom: "20px", fontWeight: "bold" }}>New Configuration</h4>
      <hr style={{ margin: "10px 0", borderTop: "2px solid #ccc", marginBottom: "20px" }} />

      <div style={{ display: "grid", gridTemplateColumns: "145px 135px",  gap: "10px", marginBottom: "10px", alignItems: "center" }}>
      <label className="form-label" style={{ fontWeight: "bold", textAlign: "left", marginLeft: "10px", display: "block" }}>
        Name
      </label>

        <input
          type="text"
          className="form-control"
          value={newConfig.name}
          onChange={(e) => setNewConfig({ ...newConfig, name: e.target.value })}
        />
        <label className="form-label" style={{ fontWeight: "bold", textAlign: "left", marginLeft: "10px", display: "block" }}>
          Universion
        </label>

        <input
          type="text"
          className="form-control"
          value={newConfig.universion}
          onChange={(e) => setNewConfig({ ...newConfig, universion: e.target.value })}
        />
      </div>

      {configRows.map((row, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            gridTemplateColumns: "145px 135px auto",
            alignItems: "center",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          <select
            className="form-select"
            value={row.versionType}
            onChange={(e) => {
              const updatedRows = [...configRows];
              updatedRows[index].versionType = e.target.value;
              updatedRows[index].selectedVersion = "";
              setConfigRows(updatedRows);
            }}
          >
            <option value="" disabled>{row.versionType || "Version Type"}</option>
            {Object.keys(versionMapping)
              .filter(
                (type) =>
                  type !== "universion" &&
                  !configRows.some((r, i) => r.versionType === type && i !== index)
              )
              .map((version) => (
                <option key={version} value={version}>
                  {version}
                </option>
              ))}
          </select>

          <input
            list={`versionOptions-${index}`}
            className="form-control"
            value={row.selectedVersion}
            onChange={(e) => {
              const updatedRows = [...configRows];
              updatedRows[index].selectedVersion = e.target.value;
              setConfigRows(updatedRows);
            }}
            placeholder="Select Version"
          />
          <datalist id={`versionOptions-${index}`}>
            {(versionMapping[row.versionType] || []).map((version, i) => (
              <option key={i} value={version} />
            ))}
          </datalist>

          {index > 0 && (
            <button
              className="btn p-0 border-0"
              style={{ color: "black", fontSize: "16px" }}
              onClick={() => handleDeleteRow(index)}
            >
              ✖
            </button>
          )}
        </div>
      ))}

      <div style={{ width: "290px", textAlign: "right" }}>
        <button
          className="btn btn-outline-primary mb-3"
          onClick={handleAddRow}
          disabled={Object.keys(versionMapping).length - 1 === configRows.length}
        >
          ➕
        </button>
      </div>

      <button className="btn btn-primary" onClick={handleCreateConfig}>
        Create Software Configuration
      </button>

      <button
        className="btn p-0 border-0 me-3"
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "20px",
          color: "black",
          background: "none",
          cursor: "pointer",
        }}
        onClick={() => setShowPopup(false)}
      >
        ✖
      </button>
    </div>
  </div>

  );
};

export default CreateSoftwareConfigPopup;
