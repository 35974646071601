import React, { useState } from 'react';
import '../../styles/TableData.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const FPGAContentsModal = ({ data }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const isValidData = Array.isArray(data.testLogs) && data.testLogs.length > 0;
    let contentSection = 0

    const fpgaContentSections = isValidData
        ? data.testLogs.map((log, index) => {
            const fpgaData = log.event_data?.additional_data?.fpga_buffer_contents;
            if (fpgaData) {
                contentSection += 1
                return (
                    <div key={index}>
                        <strong>FPGA Buffer Contents {contentSection}</strong>
                        <p>{log.event_data.event_timestamp}</p>
                        {Object.entries(fpgaData).map(([key, val], i) => (
                            <p key={i}><strong>{key}</strong>: {val.replace(/\\r\\n/g, '\n')}</p>
                        ))}
                    </div>
                );
            }
            return null;
        }).filter(entry => entry !== null)
        : [<p>No valid data provided</p>];

    const fpgaDataExists = fpgaContentSections.length > 0 && fpgaContentSections[0] !== null;

    return (
        <>
            <Button type="button" variant="outline-secondary" className="data-button" onClick={handleShow} disabled={!fpgaDataExists}>
                `#
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>FPGA Buffer Contents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {fpgaContentSections}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FPGAContentsModal;
