import React from "react";
import SoftwareVersionTable from "../components/softwareStats/SoftwareVersionTable";

const SoftwareLevelStats = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 p-6"> 
        <div className="w-full flex justify-center">
            <SoftwareVersionTable />
        </div>
    </div>
  );
};

export default SoftwareLevelStats;
