import React, { useState, useEffect } from 'react';
import PreviewGraph from './PreviewGraph.jsx';


const PreviewGraphContainer = ({ data, layout, timeSeriesData }) => {
    
    const [dataLoaded, setDataLoaded] = useState(false)

    const activeFields = layout.cellName == "deliveryGraph" ? ["balance_ml", "delivery_vol_delivered_raw"] : ["measurement_error_percent", "total_error_percent"];

    const availableFields = {
        balance_ml: { label: 'Balance Reading', units: "ml", color: 'firebrick', dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 3, opacity: 1,  display: true  },
        delivery_vol_delivered_raw: { label: 'Measured Volume', color: 'dodgerblue', units: "ml", dataType: 'number', normalizeData: false, graphType: 'line', range: [], decimalPlaces: 2, opacity: 1, display: true },
        measurement_error_percent: { label: 'Measurement Error', units: "%", color: 'dodgerblue', dataType: 'number', normalizeData: false, graphType: 'line', range: [-100, 100], decimalPlaces: 3, opacity: 1, display: true },
        total_error_percent: { label: 'Total Error', color: 'firebrick', units: "%", dataType: 'number', normalizeData: false, graphType: 'line', range: [-100, 100], decimalPlaces: 2, opacity: 1, display: true },
    }

    useEffect(() => {

        setDataLoaded(timeSeriesData && timeSeriesData.testLogs && timeSeriesData.testLogs.length > 0)

    }, [timeSeriesData]);


    const graphLines = activeFields.map(field => ({
        key: field,
        ...availableFields[field]
    }));



    return (
        <div style={{ height: "100%"}}>
            {dataLoaded ? (
                    <PreviewGraph data={timeSeriesData} lineConfigs={graphLines} graphType={layout.cellName} style={{ maxHeight: '375px'}}/>
            ) : (
                <div>Generating Graph...</div>
            )}
        </div>
    );
}

export default PreviewGraphContainer;