import React, { useState, useEffect } from 'react';
import '../../styles/TableData.css';
import Button from 'react-bootstrap/Button';
import LevelsHistogram from '../graphing/LevelsHistogram'
import ImageMetadataOverlay from '../graphing/ImageMetadataOverlay'

const DropGallery = ({ fullLog, timeSeriesData, activeImageIndex = 0, setActiveImageIndex, imageFileInfo, setImageFileInfo, imageList = []}) => {

    const [imageUrl, setImageUrl] = useState("");
    const [imageData, setImageData] = useState({})
    const [dropLogEntry, setDropLogEntry] = useState(null);


    useEffect(() => {
        if (fullLog.dropLogs && fullLog.dropLogs.length > 0) {
            // Extract number from filename
            const match = imageFileInfo.name.match(/\d+/);
            const imageNameNumber = match ? parseInt(match[0], 10) : null;
            // Find drop log entry for the current image
            const dropLog = fullLog.dropLogs[0]; // Assuming there's only one entry in dropLogs
            const eventDataKeys = Object.keys(dropLog.event_data);
            const entry = eventDataKeys.find(key => {
                const log = dropLog.event_data[key];
                return log.bg_frame <= imageNameNumber && imageNameNumber <= log.end_frame;
            });
    
            setDropLogEntry(entry ? dropLog.event_data[entry] : null);
        } else {
            setDropLogEntry(null);
        }
    }, [imageFileInfo, fullLog]);

    useEffect(() => {

        const requestDropPreview = async () => {
        
            try {
                const response = await fetch(imageFileInfo.previewUrl, {
                    method: 'GET',
                    credentials: 'include'
                });

                if (!response.ok) {
                    console.log("Error: ", response)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                } 

                const blob = await response.blob();
                
                const objectURL = URL.createObjectURL(blob);
                setImageUrl(objectURL);
                
            } catch (error) {
                console.error("Error fetching image:", error);
                setImageUrl(null);
            }
        };

        if (imageFileInfo && imageFileInfo.previewUrl) {
            requestDropPreview()
        }
       
    }, [imageList, activeImageIndex, imageFileInfo]);

    
    useEffect(() => {
        
        if (imageList.length > 0) {

            const fileInfo = (imageList[activeImageIndex])
            
            setImageFileInfo(fileInfo)
            
            if (timeSeriesData.dropImages && imageFileInfo) {
                const imageDbEntry = timeSeriesData.dropImages.find(dropImage => dropImage && "imageName" in dropImage && dropImage.imageName == imageFileInfo.name);
                setImageData(imageDbEntry || null); // Use null or another falsey value as preferred
            } else {
                setImageData(null); // Use null or another falsey value as preferred
            }

        }
        
    } , [timeSeriesData, activeImageIndex, imageList]);

    const handleNextImage = () => {

        const nextIndex = activeImageIndex < imageList.length - 1 ? activeImageIndex + 1 : 0
        setActiveImageIndex(nextIndex);
    };

    const handlePreviousImage = () => {
        const nextIndex = activeImageIndex > 0 ? activeImageIndex - 1 : imageList.length - 1 
        setActiveImageIndex(nextIndex);
    };


    const [showHistogram, setShowHistogram] = useState(false)
    const [showMetadata, setShowMetadata] = useState(false)

    const toggleHistogram = () => {setShowHistogram(showHistogram => !showHistogram)};
    const toggleMetadata = () => {setShowMetadata(showMetadata => !showMetadata)};

    const displayFull = (imageUrl, imageList) => {
        

        if (imageUrl) {
            
                return (<img key={`${imageUrl}-full`} src={imageUrl} alt="Drop Image" className="image-full" onClick={handleNextImage}/>)
                
        } else {
            return <div className="image-full">No Images Found.</div>
        };
    }

    const displayHistogram = (imageData, imageList) => {

        if (showHistogram && imageData && imageData.histogram) {
            return <LevelsHistogram show={showHistogram} data={imageData.histogram}/>
        } else { 
            return <></>
        };
    }


    const displayMetadata = (imageData, imageList) => {

        if (showMetadata && imageData) {
            return <ImageMetadataOverlay show={showMetadata} data={imageData} />
        } else { 
            return <></>
        };
    }

    
    return (
        
        <div style={{height: "100%" }}>
            <h4>{imageFileInfo.name || "--"}</h4>
            {dropLogEntry &&
                <div>
                    <p>Final Volume: {dropLogEntry.volume}</p>
                    <p>Boundary Ratio: {dropLogEntry.boundary_ratio}</p>
                </div>}
            <div className="image-full-container">
            {displayFull(imageUrl, imageList)}
            {displayHistogram(imageData, imageList)}
            {displayMetadata(imageData, imageList)}
            </div>
                   
                <Button variant={showHistogram ? "primary" : "secondary"} onClick={toggleHistogram} disabled={!imageData}>
                        Levels
                </Button>
                <Button variant={showMetadata ? "primary" : "secondary"} onClick={toggleMetadata}  disabled={!imageData}>
                        Metadata
                </Button>
                <div className="gallery-nav">
                            <Button type="button" variant="primary" className="btn btn-primary btn-sm" onClick={handlePreviousImage} >←</Button>
                            <span>{`${activeImageIndex}`} / {`${imageList.length}`}</span>
                            <Button type="button" variant="primary" className="btn btn-primary btn-sm" onClick={handleNextImage} >→</Button>
                </div>
        </div>
        );
}

export default DropGallery;
