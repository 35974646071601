import React from 'react';
import TestLogBrowserModal from '../TestLogBrowserModal';
import TestGraphBrowserModal from "../graphing/TestGraphBrowserModal";
import FPGAContentsModal from "./FPGAContentsModal";
import TestMarkupModal from "./TestMarkupModal";
import '../../styles/TableData.css';

const DataNavCell = ({ data, testFilter, setTestFilter, graphSettings, setGraphSettings, timeSeriesData }) => {
    return (
        <div style={{ width: '100%', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
            <TestLogBrowserModal 
                test_series={data.test_series}
                test_number={data.test_number}
                id={data.id}
                infdev_sn={data.infdev_sn}
            />
            <TestGraphBrowserModal 
                graphSettings={graphSettings}
                setGraphSettings={setGraphSettings}
                data={data}
                timeSeriesData={timeSeriesData}
                id={data.id}
            />
            <FPGAContentsModal
                data={timeSeriesData}
            /> 

            <TestMarkupModal
                data={data}
            />
        </div>
    );
};

export default DataNavCell;
