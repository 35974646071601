import React from 'react';
import '../../styles/TableData.css';

const TestHeader = ({ data }) => {

    
    const debugRow = (Number(data.parentTkId) > 100)
    const debugTextColor = {color:'#666666'}
    const debugBgColor = {backgroundColor:'#333333'}
    const formatDate = (dateTimeString) => {
        // Create a new Date object from the dateTimeString
        const date = new Date(dateTimeString);

        // Use Intl.DateTimeFormat to format the date and time
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
        }).format(date);

        return `${formattedDate}`;
    };

    const formatTime = (dateTimeString) => {
        // Create a new Date object from the dateTimeString
        const time = new Date(dateTimeString);

        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false,
            timeZone: 'America/New_York' // EST timezone
        }).format(time);
    
        return `${formattedTime} EST`;
    };
    
    return (
        <span className="test-header-cell" >
            <div className="test-header-content" style={debugRow ? {...debugBgColor, ...debugTextColor} : {}}>
                <h4> {debugRow ? "Debug" : "Test" } {data.test_number}</h4>
                <span className="vertical-cell"><div style={debugRow ? debugTextColor : {}}>{data.test_start_time ? formatDate(data.test_start_time) : ""}</div><div style={debugRow ? debugTextColor : {}}>{data.test_start_time ? formatTime(data.test_start_time) : ""}</div></span>
                <span className="vertical-cell">
                    <div 
                        style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            ...(debugRow ? debugTextColor : {}),
                        }}
                    >
                        <span className="label-text" style={{ whiteSpace: "nowrap" }}>SN:</span>
                        <span style={{ minWidth: "50px", textAlign: "left" }}>
                            {data.infdev_sn || ""}
                        </span>
                    </div>
                    <div 
                        style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            ...(debugRow ? debugTextColor : {}),
                        }}
                    >
                        <span className="label-text" style={{ whiteSpace: "nowrap" }}>ID:</span>
                        <span style={{ minWidth: "50px", textAlign: "left" }}>
                            {data.id || ""}
                        </span>
                    </div>
                </span>
                <span className="vertical-cell">
                    <div 
                        style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            ...(debugRow ? debugTextColor : {}),
                        }}
                    >
                        <span className="label-text" style={{ whiteSpace: "nowrap" }}>RATE:</span>
                        <span style={{ minWidth: "100px", textAlign: "left" }}>
                            {data.delivery_rate || ""}<span className="unit-text">mL/hr</span>
                        </span>    
                    </div>
                    <div 
                        style={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            ...(debugRow ? debugTextColor : {}),
                        }}
                    >
                        <span className="label-text" style={{ whiteSpace: "nowrap" }}>VTBI:</span>
                        <span style={{ minWidth: "100px", textAlign: "left" }}>
                            {data.delivery_vtbi || ""}<span className="unit-text">mL</span>
                        </span>
                    </div>
                </span>
                
            </div>
        </span>
    );
};

export default TestHeader;
