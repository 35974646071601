import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const LevelsHistogram = ({ show, data }) => {
    const d3Container = useRef(null);

    console.log("histogram requested for ", data)
    useEffect(() => {
        // Set up SVG and dimensions
        const svgWidth = 1920;
        const svgHeight = 1080;
        const margin = { top: 20, right: 15, bottom: 20, left: 15 };
        const width = svgWidth - margin.left - margin.right;
        const height = svgHeight - margin.top - margin.bottom;

        // Clear previous SVG elements
        d3.select(d3Container.current).selectAll("*").remove();

        // Initialize SVG
        const svg = d3.select(d3Container.current).append('svg')
            .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Scales
        const xScale = d3.scaleBand()
            .domain(d3.range(256))
            .range([0, width])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data)])
            .range([height, 0]);

        // Bars
        svg.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", (d, i) => xScale(i))
            .attr("y", d => yScale(d))
            .attr("width", xScale.bandwidth())
            .attr("height", d => height - yScale(d))
            .attr("fill", "#69b3a2");

        // Text at the top of the bars
        // Key at the bottom of the bars
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale).ticks(0, 255, 32).tickSize(0))
            .select(".domain").remove();

    }, [data]);

    return (
        <div className="image-full" ref={d3Container} />
    );
};

export default LevelsHistogram;
