import React, { useState, useEffect } from 'react';
import { Dropdown, InputGroup, Button, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FilterPicker = ({ col, vals, filterConfig, handleSelectChange, handleRangeSubmit, testFilter}) => {
  const [minValue, setMinValue] = useState(testFilter[col] && testFilter[col].gt || '');
  const [maxValue, setMaxValue] = useState(testFilter[col] && testFilter[col].lt || '');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#e9ecef' : '#ffffff',
      borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
      boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(0, 123, 255, 0.25)' : null,
      '&:hover': {
        borderColor: '#86b7fe',
      },
      minHeight: '30px', // Set your desired height
      height: '30px',
      minWidth: '120px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#0d6efd',
      color: '#ffffff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#ffffff',
      ':hover': {
        backgroundColor: '#0a58ca',
        color: '#ffffff',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d', // Adjust placeholder color for better visibility
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '30px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0d6efd' : state.isFocused ? '#e9ecef' : '#ffffff',
      color: state.isSelected ? '#ffffff' : '#000000',
      '&:hover': {
        backgroundColor: '#0a58ca',
        color: '#ffffff',
      },
      fontSize: '12px',
    })
  };

  useEffect(() => {
    if (testFilter[col] && testFilter[col] !== filterConfig.default) {
      const selected = Array.isArray(testFilter[col]) ? testFilter[col] : [testFilter[col]];
      setSelectedOptions(selected.map(value => ({ value, label: value })));
    } else {
      setSelectedOptions([]);
    }
  }, [testFilter, col, filterConfig.default]);
  
  const handleChange = (selected) => {
    if (selected === null || selected.length === 0) {
        setSelectedOptions([]);
        handleSelectChange(col, "All");
    } else {
        setSelectedOptions(selected);
        if (col === "errorcode" || col === "alarm_status") {
            // Send single value if only one option is selected
            handleSelectChange(col, selected.length === 1 ? selected[0].value : "All");
        } else {
            handleSelectChange(col, selected.map(option => option.value));
        }
    }
};

  const options = vals.map(val => ({
      value: val.uniqueVal,
      label: `${val.uniqueVal} (${val.count})`
  }));

  if (filterConfig.extraVals) {
      filterConfig.extraVals.forEach(extraVal => {
          options.push({ value: extraVal, label: extraVal });
      });
  }

  if (filterConfig.pickerType === "range") {
    
    return (
      <div style={{margin: "auto"}}>
        <InputGroup className="mb-3" size="sm" >
        <InputGroup.Text data-bs-theme="dark" id="inputGroup-sizing-sm">{filterConfig.title}</InputGroup.Text>
          <FormControl
            placeholder={Math.min(...vals.map(val => parseFloat(val.uniqueVal).toPrecision(2)))}
            aria-label="Minimum value"
            value={minValue}
            onChange={e => setMinValue(e.target.value)}
          />
          <FormControl
            placeholder={Math.max(...vals.map(val => parseFloat(val.uniqueVal).toPrecision(2)))}
            aria-label="Maximum value"
            value={maxValue}
            onChange={e => setMaxValue(e.target.value)}
          />
          <Button variant="outline-secondary" onClick={() => handleRangeSubmit(col, minValue, maxValue)}><FilterAltIcon /></Button>
        </InputGroup>
        </div>
    );
  } else {
    return (
      <Select
        className="filter-bar-dropdown"
        styles={customStyles}
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        isMulti
        placeholder={filterConfig.title}
        isDisabled={!vals.length}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
        
    );
  }
};

export default FilterPicker;
