import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Form, Button, InputGroup, Collapse, Tab, Tabs, Nav, Pagination, FormControl, Overlay, Popover } from 'react-bootstrap';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import FirstPage from '@mui/icons-material/FirstPage';
import Search from '@mui/icons-material/Search';
import FilterPicker from './filters/FilterPicker.jsx';
import '../../styles/TableData.css';
import { getSimilarTests, getLastCalibration } from '../../services/apiService.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TestDataFilterBar = ({ testFilter, setTestFilter, filterCategory, setFilterCategory, testData, setTestData, pageNum, setPageNum, pageLength, numMatchingTests, defaultTestFilter, uniqueFilterValues }) => {


    // Placeholder. Pull this from the DB / user config.

    const filterDropdownConfig = {
        "test_start_time": { title: "Time Window", default: "Last 24 Hours", extraVals: ["Live", "Last 24 Hours", "Last 7 Days", "Last 30 Days", "All (Slow)"], pickerType: "dropdown" },
        "test_time": { title: "Time Window", default: "Last 24 Hours", extraVals: ["Live", "Last 24 Hours", "Last 7 Days", "Last 30 Days", "All (Slow)"], pickerType: "dropdown" },
        "infdev_sn": { title: "Serial #", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "test_set_number": { title: "IV Set", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Test Setup", pickerType: "dropdown" },
        "delivery_rate": { title: "Rate", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Test Setup", pickerType: "dropdown" },
        "delivery_vtbi": { title: "VTBI", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Test Setup", pickerType: "dropdown" },
        "needle_gauge": { title: "Needle", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Test Setup", pickerType: "dropdown" },
        "fluid_type": { title: "Fluid", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Test Setup", pickerType: "dropdown" },
        "alarm_status": { title: "Alarm", default: "All", nullName: "No Alarms", notNullName: "Only Alarms", extraVals: [], navTab: "Errors", pickerType: "dropdown" },
        "errorcode": { title: "Error", default: "All", nullName: "No Errors", notNullName: "Only Errors", extraVals: [], navTab: "Errors", pickerType: "dropdown" },
        "exclude_flag": { title: "Exclusions", default: "All", nullName: "Null", notNullName: "Only Exlcusions", extraVals: ["Show All", "Show Only Exclusions", "Hide Exclusions"], navTab: "Errors", pickerType: "dropdown" },
        "test_time_elapsed_seconds": { title: "Runtime (sec)", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Results", pickerType: "range" },
        "measurement_error_percent": { title: "Meas Err (%)", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Results", pickerType: "range" },
        "total_error_percent": { title: "Total Err (%)", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Results", pickerType: "range" },
        "actual_vol_delivered_ml": { title: "Balance Vol (ml)", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Results", pickerType: "range" },
        "droplog_boundary_ratio_avg": { title: "Boundary Avg (%)", default: "All", nullName: "", notNullName: "", extraVals: [], navTab: "Results", pickerType: "range" },
        "stm8_pri_fw_build": { title: "AVR Pri FW", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "stm8_sec_fw_build": { title: "AVR Sec FW", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "imx7_m4_version": { title: "iMX7 M4 Ver", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "stm32_m4_version": { title: "STM32 Ver", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "a7_sw_version": { title: "A7 SW Ver", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "a7_sw_branch": { title: "A7 SW Branch", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "fcl_version": { title: "FCL Version", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" },
        "linux_kernel_ver": { title: "Linux Kernel", default: "All", nullName: "Null", notNullName: "", extraVals: [], navTab: "Device Config", pickerType: "dropdown" }
    }

    // End Placeholder Data

    const [pageString, setPageString] = useState("--");
    const [lastPage, setLastPage] = useState(0);
    const [filterState, setFilterState] = useState(testFilter);
    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState('testNumber');
    const [searchPlaceholder, setSearchPlaceholder] = useState('Test #');
    
    const [filterExpand, setFilterExpand] = useState(false);
    const [rangeSelection, setRangeSelection] = useState({});
    const [versions, setVersions] = useState({});
    const [calDates, setCalDates] = useState({});
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCustomWindowMenu, setShowCustomWindowMenu] = useState(false);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);
    const customWindowRef = useRef(null);
    const subMenuRef = useRef(null);
    const [isSoftwareFilterActive, setIsSoftwareFilterActive] = useState(false);


    const filterCategories = ["Test Setup", "Device Config", "Results", "Errors"]

    useEffect(() => {
        async function fetchVersions() {
            try {
                const apiData = await getSimilarTests();

                setVersions(apiData || []);

                //console.log("help Recent Versions: ", apiData)

            } catch (error) {
                console.error('Error fetching API data: ' + error);
            }
        }


        fetchVersions();

    }, []);

    /* useEffect(() => {
        async function fetchCalibrationDates() {
            try {
                const currentDate = new Date();
                const ninetyDaysBefore = new Date();
                ninetyDaysBefore.setDate(currentDate.getDate() - 90);
    
                let start_date = ninetyDaysBefore.toISOString();
                let end_date = currentDate.toISOString();
    
                if (testFilter.test_end_date) {
                    end_date = new Date(testFilter.test_end_date).toISOString();
                }
    
                if (testFilter.test_start_date) {
                    start_date = new Date(testFilter.test_start_date).toISOString();
                } else if (testFilter.startDateOffset) {
                    const end_date_obj = new Date(end_date);
                    const offset_start_date = new Date(end_date_obj.setDate(end_date_obj.getDate() - testFilter.startDateOffset));
                    start_date = offset_start_date.toISOString();
                }
    
                const apiData = await getLastCalibration({ start_date, end_date });
    
                setCalDates(apiData || []);
    
                console.log("help Last Calibrations: ", apiData)
    
            } catch (error) {
                console.error('Error fetching API data: ' + error);
            }
        }
    
        fetchCalibrationDates();
    }, [testFilter]);
 */

    useEffect(() => {

        console.log(filterCategory)
        setFilterState(testFilter)
        console.log("--> testFilter: ", testFilter)
        //console.log("--> unique vals: ", uniqueFilterValues)
        if (!isNaN(pageNum) && !isNaN(pageLength) && !isNaN(numMatchingTests)) {
            const pageStart = pageNum * pageLength
            const pageEnd = Math.min(pageStart + pageLength - 1, numMatchingTests)
            const newLastPage = Math.ceil(numMatchingTests / pageLength) || 0
            const newString = `${pageStart} - ${pageEnd} of ${numMatchingTests}`

            setPageString(newString)
            setLastPage(newLastPage)

        } else {
            setPageString("X")
            setLastPage(0)
        }

    }, [pageNum, pageLength, numMatchingTests, testFilter, testData, uniqueFilterValues, filterCategory]);

    const handleSelectTimeWindow = (eventKey) => {
        if (eventKey === "Custom Window") {
            setShowCustomWindowMenu(true);
            setShowDropdown(true);
        } else {
            const newOffset = eventKey === "Last 24 Hours" ? 1
                : eventKey === "Last 7 Days" ? 7
                    : eventKey === "Last 30 Days" ? 30
                        : eventKey === "All (Slow)" ? 0 : 1;

            setTestFilter(prevFilter => ({
                ...prevFilter,
                test_start_time: eventKey,
                startDateOffset: newOffset,
                testNumber: '',
                test_start_date: null,
                test_end_date: null
            }));
            setTestData([])
            setPageNum(0)

        }
    };

    const handleApply = () => {
        // Handle apply logic
        setShowCustomWindowMenu(false);
        setShowDropdown(false);
        console.log('Start Date:', customStartDate);
        console.log('End Date:', customEndDate);
        if (customStartDate && customEndDate) {
            const adjustedEndDate = new Date(customEndDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
            const dateRange = `${customStartDate.toLocaleDateString()} to ${customEndDate.toLocaleDateString()}`;
            setTestFilter({
                ...testFilter,
                timeWindow: 'Custom Window',
                test_start_time: dateRange,
                test_start_date: customStartDate,
                test_end_date: adjustedEndDate
            });
        }
    };

    const handleSetFilterCategory = (newCat) => {
        setFilterCategory(newCat)
        if (!filterExpand) {
            setFilterExpand(true)
        } else if (filterExpand && newCat == filterCategory) {
            setFilterExpand(false)
        }
    };

    const handleSearchTypeChange = (type) => {
        setSearchType(type);
        const placeholderText = type === 'testNumber' ? 'Test #' : type === 'infdev_sn' ? 'Serial #' : 'Test ID';
        setSearchPlaceholder(placeholderText);
    };

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleRangeSubmit = (col, min, max) => {

        setTestData([]);
        if (searchValue) setSearchValue('');

        setTestFilter(prevFilter => ({
            ...prevFilter,
            [col]: { gt: min, lt: max },
            testNumber: '',
        }));
        setPageNum(0);

    };

    const handleSearch = (e) => {
        e.preventDefault();
        setTestData([]);

        // Create a new filter object that preserves all other filters
        const newFilter = {
            ...testFilter,
            timeWindow: "All (Slow)",
            rate: "All",
            needle: "All",
            fluid: "All",
            alarm: "All",
            error: "All"
        };

        // Set the specific filter based on searchType
        newFilter[searchType] = searchValue;

        // Clear other specific filters
        if (searchType !== 'testNumber') newFilter.testNumber = 'All';
        if (searchType !== 'infdev_sn') newFilter.infdev_sn = 'All';
        if (searchType !== 'id') newFilter.id = 'All';

        console.log("helpnewFilter: ", newFilter);

        setTestFilter(newFilter);
        console.log("helptestFilter: ", testFilter);
        setPageNum(0);
    };

    const handleFilterSimilar = (e) => {
        setTestData([]);

        // Create a new filter object that includes the dynamic criteria based on tk_id
        const newFilter = {
            ...testFilter,
            //rate: "All",
            //needle: "All",
            //fluid: "All",
            //alarm: "All",
            //error: "All",
            //testNumber: "All",
            //infdev_sn: "All",
            //id: 'All',
            versions: versions.map(item => ({
                tk_id: item.tk_id,
                linux_kernel_ver: item.linux_kernel_ver,
                stm32_m4_version: item.stm32_m4_version,
                fcl_version: item.fcl_version,
                imx7_m4_version: item.imx7_m4_version,
                stm8_pri_fw_build: item.stm8_pri_fw_build,
                stm8_sec_fw_build: item.stm8_sec_fw_build
            }))
        };
        console.log("helpnewFilter: ", newFilter);

        setTestFilter(newFilter);
        console.log("helptestFilter: ", testFilter);
        setPageNum(0);
        
    };

    /* const handleFilterLastCal = (e) => {
        e.preventDefault();
        setTestData([]);

        // Create a new filter object that includes the dynamic criteria based on tk_id
        const newFilter = {
            ...testFilter,
            rate: "All",
            needle: "All",
            fluid: "All",
            alarm: "All",
            error: "All",
            testNumber: "All",
            infdev_sn: "All",
            id: 'All',
            lastCals: calDates.map(item => ({
                tk_id: item.testKitId,
                lastCalDate: item.last_change_timestamp
            }))
        };
        console.log("helpnewFilter: ", newFilter);

        setTestFilter(newFilter);
        console.log("helptestFilter: ", testFilter);
        setPageNum(0);
    }; */

    const handleSelectChange = (col, selectedValues) => {
        
        setTestData([]);
        if (searchValue) setSearchValue('');

        setTestFilter(prevFilter => ({
            ...prevFilter,
            [col]: selectedValues,
            testNumber: '',
        }));
        setPageNum(0);
        
    };


    const handleClearFilter = () => {
        setTestFilter(defaultTestFilter)
        setTestData([])
        setPageNum(0)
    };


    const handleNextPage = () => {
        setTestData([])
        setPageNum(pageNum + 1);
    };

    const handlePrevPage = () => {
        setTestData([])
        setPageNum(pageNum ? pageNum - 1 : 0);
    };

    const handleFirstPage = () => {
        setTestData([])
        setPageNum(0);
    };

    const renderFiltersForCategory = (filterCategory) => {
        return Object.entries(uniqueFilterValues).map(([col, vals]) => {
            if (filterDropdownConfig[col].navTab !== filterCategory) return null;
            if (filterDropdownConfig[col]) {
                return <FilterPicker key={`filter-picker-${col}`} col={col} vals={vals} filterConfig={filterDropdownConfig[col]} handleSelectChange={handleSelectChange} handleRangeSubmit={handleRangeSubmit} testFilter={testFilter} />;
            } else return <></>
        }).filter(component => component !== null);
    };

    

    const handleSoftwareFilterClick = () => {
        
        if(!isSoftwareFilterActive){
            //setIsSoftwareFilterActive(true);
            
            handleFilterSimilar();
            
        } else {
            setTestData([]);

            // Create a new filter object that includes the dynamic criteria based on tk_id
            const newFilter = {
                ...testFilter,
                versions: null
            };
            setTestFilter(newFilter);
            setPageNum(0);
            //setIsSoftwareFilterActive(false);
        } 
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="filter-bar-container" onMouseOver={() => setFilterExpand(true)}
            onMouseLeave={() => setFilterExpand(false)} onClick={stopPropagation}>
            <Nav className="filter-bar-main">
                <Pagination data-bs-theme="dark" size="sm" style={{ margin: "auto 1rem auto 1rem" }}>
                    <Pagination.First disabled={!pageNum} onClick={handleFirstPage} />
                    <Pagination.Prev disabled={!pageNum} onClick={handlePrevPage} />
                    <Pagination.Item><strong>{pageString}</strong></Pagination.Item>
                    <Pagination.Next disabled={isNaN(numMatchingTests) || (pageNum * pageLength + pageLength - 1 > numMatchingTests)} onClick={handleNextPage} />
                </Pagination>

                <Dropdown className="filter-bar-dropdown" onSelect={handleSelectTimeWindow} onToggle={() => setShowDropdown(!showDropdown)}>
                    <Dropdown.Toggle variant={testFilter.timeWindow === 'All (Slow)' ? "outline-secondary" : "primary"}>
                        {testFilter.test_start_time}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {["Live", "Last 24 Hours", "Last 7 Days", "Last 30 Days", "All (Slow)", "Custom Window"].map(val => (
                            <Dropdown.Item
                                key={`timeWindow-${val}`}
                                eventKey={val}
                                onMouseEnter={() => val === "Custom Window" && setShowCustomWindowMenu(true)}
                                onMouseLeave={() => val === "Custom Window" && setShowCustomWindowMenu(false)}
                                onClick={(e) => {
                                    if (val === "Custom Window") {
                                        e.preventDefault(); // Prevent the default action
                                        e.stopPropagation(); // Stop event propagation to keep dropdown open
                                    } else {
                                        setShowDropdown(false); // Close the dropdown for other items
                                    }
                                }}
                                ref={val === "Custom Window" ? customWindowRef : null}
                            >
                                {val}
                                {val === "Custom Window" && showCustomWindowMenu && (
                                    <div
                                        ref={subMenuRef}
                                        style={{
                                            position: 'absolute',
                                            top: 165,
                                            left: '100%',
                                            zIndex: 1000,
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            padding: '10px'
                                        }}
                                        onMouseEnter={() => setShowCustomWindowMenu(true)}
                                        onMouseLeave={() => setShowCustomWindowMenu(false)}
                                        
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                                            <label style={{ color: 'black', width: '100px' }}>Start Date:</label>
                                            <DatePicker
                                                selected={customStartDate}
                                                onChange={date => setCustomStartDate(date)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }} onClick={(e) => e.stopPropagation()}>
                                            <label style={{ color: 'black', width: '100px' }}>End Date:</label>
                                            <DatePicker
                                                selected={customEndDate}
                                                onChange={date => setCustomEndDate(date)}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-end mt-2">
                                            <Button variant="secondary" onClick={() => setShowCustomWindowMenu(false)} className="me-2">
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleApply}>
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                <Tabs data-bs-theme="dark" defaultActiveKey={filterCategory} onSelect={handleSetFilterCategory} justify className="filter-bar-tabs">
                    {filterCategories.map(c => (
                        <Tab eventKey={c} title={c} key={`show-filter-cat-${c}`} />
                    ))}
                </Tabs>

                <Button size="sm" variant="outline-warning" onClick={handleClearFilter} style={{ marginLeft: "auto", marginRight: "auto" }}>
                    Clear Filter
                </Button>
                <form onSubmit={handleSearch}>
                    <InputGroup className="filter-bar-search">
                        <Dropdown onSelect={handleSearchTypeChange}>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">

                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="testNumber" className="p-3">Test #</Dropdown.Item>
                                <Dropdown.Item eventKey="infdev_sn" className="p-3">Serial #</Dropdown.Item>
                                <Dropdown.Item eventKey="id" className="p-3">Test ID</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control
                            type="text"
                            placeholder={searchPlaceholder}
                            value={searchValue}
                            onChange={handleSearchInputChange}
                        />
                        <Button variant="outline-secondary" type="submit">
                            <Search />
                        </Button>
                    </InputGroup>
                </form>
            </Nav>

            <Collapse in={filterExpand} dimension="height">
                <div className="filter-bar-collapse">
                    <div className="filter-bar-sub">
                        {renderFiltersForCategory(filterCategory)}
                        {filterCategory === 'Test Setup' && (
                            <Button
                                variant={isSoftwareFilterActive ? "primary btn-sm" : "outline-secondary btn-sm"}
                                onClick={handleSoftwareFilterClick}
                            >
                                Match Latest Software
                            </Button>
                        )}
                        {/* <Button variant="outline-secondary btn-sm" onClick={handleFilterLastCal}>
                            Match Latest Calibration
                        </Button> */}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default TestDataFilterBar;